import React from 'react';
import SpinnerIcon from '../../../../components/SpinnerIcon';

const ModalWatting = () => {
	return (
		<div className={`modal waitting show-modal`} id="myModal">
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-body">
						<span>Pave Is Hard Working</span>
						<SpinnerIcon />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalWatting;
