import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { statusProcess, sumaryTask } from './../operations';
import { logout } from '../../auth/operations';
import InfomationTask from './components/InfomationTask';
import SpinnerIcon from '../../../components/SpinnerIcon';
import ModalShowErr from './components/ModalShowErr';
import CarAni from '../../ui/components/animations/CarAni';
import BoxHotKeys from './components/BoxHotKeys';
const Start = (props) => {
  let { inspection } = props;
  const [loadSumary, setloadSumary] = useState(true);
  const [showmodalErr, setshowmodalErr] = useState(false);
  const [messErr, setmessErr] = useState('');
  let unAuthor =
    inspection &&
    inspection.error &&
    inspection.error.response &&
    inspection.error.response.data
      ? inspection.error.response.data
      : '';
  const error =
    inspection &&
    inspection.error &&
    inspection.error.response &&
    inspection.error.response.data &&
    inspection.error.response.data.code
      ? inspection.error.response.data.code
      : '';
  const sumTaskTotal = inspection.sumaryTask ? inspection.sumaryTask : null;
  const { user } = props.auth;
  const skillsUser = user.skills ? user.skills : [];
  const userId = user && user._id ? user._id : null;
  const skills = [
    'vin',
    'trim_id',
    'ext_id',
    'damage_full',
    'damage_other',
    'damage_body',
    'qc',
    'plate_number',
    'odom',
    'interior',
    'tire',
    'glass',
    'qc_plate',
  ];
  useEffect(() => {
    if (
      unAuthor === 'Unauthorized' ||
      error === 'SESSION_EXPIRED' ||
      error === 'SHIFT_EXPIRED'
    ) {
      let errMessage =
        inspection &&
        inspection.error &&
        inspection.error.response &&
        inspection.error.response.data &&
        inspection.error.response.data.message
          ? inspection.error.response.data.message
          : '';
      setmessErr(errMessage);
      setshowmodalErr(true);
    }
    if (userId && !error && !unAuthor) {
      props.sumaryTask();
    }
  }, [userId, error, unAuthor]);
  useEffect(() => {
    const total = sumTaskTotal && sumTaskTotal.total ? sumTaskTotal.total : 0;
    if (total || total === 0) {
      setloadSumary(false);
    }
  }, [sumTaskTotal]);
  const process = () => {
    const data = {};
    data['status'] = 'Waiting';
    props.statusProcess(data);
  };
  return (
    <div className="start">
      <div className="start-info ">
        <div className="start-inspec container ">
          <div className="row">
            <div className="col-6">
              <CarAni />
              <div
                style={{
                  position: 'absolute',
                  width: '95%',
                  display: 'flex',
                  justifyContent: 'center',
                  top: '25px',
                }}
              >
                <span
                  className="badge badge-dark btn-inspect text-uppercase text-head"
                  onClick={() => process()}
                  style={{
                    padding: '15px 20px',
                    fontSize: '19px',
                    cursor: 'pointer',
                  }}
                >
                  Start Inspect
                </span>
              </div>
            </div>

            <div className="col-6">
              <div
                style={{
                  marginTop: '10px',
                  width: '100%',
                  height: '90%',
                  background: '#fff',
                  opacity: '0.8',
                  borderRadius: '15px',
                  padding: '10px 0px',
                }}
              >
                <div
                  className="title mb-1"
                  style={{
                    color: '#321640',
                    fontSize: '28px',
                    textAlign: 'center',
                  }}
                >
                  Skills
                </div>
                <div className="row">
                  {skills.map((s, i) => {
                    return (
                      <div key={`Skill_${i}`} className="col-6">
                        <div
                          className="custom-control text-head"
                          style={{
                            opacity: skillsUser.includes(s) ? '1' : '0.2',
                          }}
                        >
                          <div className="mr-2 d-inline">
                            <img
                              style={{
                                width: '25px',
                                display: 'inline-block mr-2',
                              }}
                              alt={s}
                              src={`/img/skills/${s}.png`}
                            ></img>
                          </div>

                          <label
                            className="text-sm text-uppercase"
                            htmlFor={`Skill_${s}`}
                          >
                            {s.toUpperCase()}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="info-task">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="tool-hotkey mb-2">
                  <BoxHotKeys></BoxHotKeys>
                </div>
              </div>
              <div className="col-12">
                <div className="total">
                  {loadSumary ? <SpinnerIcon /> : null}
                  <InfomationTask
                    sumTaskTotal={sumTaskTotal}
                    textTotal="YOUR STATISTICS"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showmodalErr ? (
        <ModalShowErr messErr={messErr} resetError={() => props.logout()} />
      ) : null}
    </div>
  );
};
const mapStateToProps = (state) => ({
  inspection: state.inspection,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  statusProcess,
  sumaryTask,
  logout,
})(Start);
