import React from 'react';
import { Link } from 'react-router-dom';

const Back = () => {
	return (
		<h2 className="subtitle">
			<small>
				<Link to="/training" className="text-logan">
					<i className="fa fa-arrow-left" />
					<span>Training</span>
				</Link>
			</small>
		</h2>
	);
};

export default Back;
