import * as types from './types';

const initialState = {
	list: { data: [], count: 0, pages: 0, current: 1, limit: 10, filters: {} },
	item: null,
	files: {},
	settings: {},
	detail: null,
	error: null,
	loading: false,
	uploaded: false
};

export default function(state = initialState, action) {
	switch (action.type) {
		case types.GET_CAGES:
			return {
				...state,
				error: null,
				loading: false,
				item: null,
				files: null,
				list: action.payload.list,
				settings: action.payload.settings
			};
		case types.GET_CAGE:
			return {
				...state,
				error: null,
				loading: false,
				item: action.payload.cage,
				files: action.payload.files,
				settings: action.payload.settings,
				uploaded: false
			};
		case types.GET_CAGE_DETAIL:
			let detail = action.payload.cageDetail;
			detail.files = action.payload.files || {};
			return {
				...state,
				error: null,
				loading: false,
				detail: detail
			};
		case types.POST_CAGE_NOTE:
			return {
				...state,
				error: null,
				loading: false,
				item: action.payload.cage
			};
		case types.PUT_CAGE_DETAIL:
			let { files } = state;
			const {
				cage,
				cageDetail,
				photoUrl,
				cageUrl,
				labelUrl
			} = action.payload;

			if (cage && files[cage.code]) {
				let file = files[cage.code];
				file.photoUrl = photoUrl || file.photoUrl;
				file.cageUrl = cageUrl || file.cageUrl;
				file.labelUrl = labelUrl || file.labelUrl;
				files[cage.code] = file;
			}
			return {
				...state,
				error: null,
				loading: false,
				item: cage,
				files: files,
				detail: cageDetail
			};
		case types.PUT_CAGE_STATUS:
			if (state.item) {
				state.item.status = action.payload.status || state.item.status;
			}
			return {
				...state,
				error: null,
				loading: false,
				success: 'status-updated'
			};
		case types.FILE_UPLOAD:
			return {
				...state,
				uploaded: true
			};
		case types.IS_LOADING:
			return {
				...state,
				loading: true
			};
		case types.HAS_ERROR:
			return {
				...state,
				error: action.payload
			};
		default:
			return state;
	}
}
