import React from 'react';

const Testing = (props) => {
	return (
		<span className="ribbon">
			<span>TEST MODE</span>
		</span>
	);
};

export default Testing;
