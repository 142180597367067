export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_CSRF = 'GET_CSRF';
export const GET_QUEUE_TASKS = 'GET_QUEUE_TASKS';
export const GET_REJECT_TASKS = 'GET_REJECT_TASKS';
export const GET_QUEUE_TASK = 'GET_QUEUE_TASK';
export const GET_CURRENT_TASK = 'GET_CURRENT_TASK';
export const SET_CURRENT_TASK = 'SET_CURRENT_TASK';
export const CLEAR_CURRENT_TASK = 'CLEAR_CURRENT_TASK';
export const GET_ACTIVE_TASK = 'GET_ACTIVE_TASK';
export const GET_APPRAISAL_TASK = 'GET_APPRAISAL_TASK';
export const IS_WAITING = 'IS_WAITING';
export const LOADING = 'LOADING';
export const CHANGE_START = 'CHANGE_START';
export const GET_INSEPECTION = 'GET_INSEPECTION';
export const ACCEPT_PHOTO = 'ACCEPT_PHOTO';
export const REJECT_PHOTO = 'REJECT_PHOTO';
export const FINISH_PHOTO = 'FINISH_PHOTO';
