import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';

import moment from 'moment-timezone';

const defaultDate = new Date(2020, 2, 20, 0, 0, 0);
const defaultTz = 'America/Toronto';
const localTz = 'Asia/Ho_chi_minh';
const defaultTimeInterval = 30;

const initTime = time => {
	const inputTime = moment(time, 'HH:mm');
	const localTzTime = moment.utc(time, 'HH:mm').tz(localTz);
	const defTzTime = moment.utc(time, 'HH:mm').tz(defaultTz);
	return {
		input: inputTime.toDate(),
		rawInput: time,
		local: localTzTime.format('HH:mm'),
		default: defTzTime.format('HH:mm')
	};
};

const TimeRangePicker = props => {
	const { label, value, name, error, onChange } = props;
	const [time, setTime] = useState({
		input: {},
		rawInput: {},
		localTz: {},
		defTz: {}
	});

	useEffect(() => {
		const time =
			typeof value === 'object' ? value : { from: '00:00', to: '00:00' };

		if (time.from && time.to) {
			const initTimeFrom = initTime(time.from);
			const initTimeTo = initTime(time.to);

			setTime({
				input: { from: initTimeFrom.input, to: initTimeTo.input },
				rawInput: {
					from: initTimeFrom.rawInput,
					to: initTimeTo.rawInput
				},
				localTz: { from: initTimeFrom.local, to: initTimeTo.local },
				defTz: { from: initTimeFrom.default, to: initTimeTo.default }
			});
		}
	}, [value]);

	const onTimeChange = (key, date) => {
		const processTime = initTime(moment(date).format('HH:mm'));

		const newRawInput = {
			...time.rawInput,
			[key]: processTime.rawInput
		};

		const newLocalTz = {
			...time.localTz,
			[key]: processTime.local
		};
		const newDefTz = {
			...time.defTz,
			[key]: processTime.default
		};
		setTime({
			...time,
			input: { ...time.input, [key]: processTime.input },
			rawInput: newRawInput,
			localTz: newLocalTz,
			defTz: newDefTz
		});
		onChange({ target: { name, value: newRawInput } });
	};

	return (
		<div className="form-group">
			{label && (
				<label className="text-head text-uppercase text-logan display-7">
					{label}
				</label>
			)}
			<div className="row">
				<div className="col-6">
					<label className="text-head text-uppercase text-logan small">
						From
					</label>
					<DatePicker
						selected={
							(time && time.input && time.input.from) ||
							defaultDate
						}
						showTimeSelect
						showTimeSelectOnly
						timeIntervals={defaultTimeInterval}
						timeCaption="From"
						timeFormat="HH:mm"
						dateFormat="HH:mm"
						className={`form-control ${error ? 'is-invalid' : ''}`}
						onChange={date => {
							onTimeChange('from', date);
						}}
					/>
				</div>
				<div className="col-6">
					<label className="text-head text-uppercase text-logan small">
						To
					</label>
					<DatePicker
						selected={
							(time && time.input && time.input.to) || defaultDate
						}
						showTimeSelect
						showTimeSelectOnly
						timeIntervals={defaultTimeInterval}
						timeCaption="To"
						timeFormat="HH:mm"
						dateFormat="HH:mm"
						className={`form-control ${error ? 'is-invalid' : ''}`}
						onChange={date => {
							onTimeChange('to', date);
						}}
					/>
				</div>
				<div className="col-12 mt-2">
					<label className="text-head text-uppercase text-logan small mt-2">
						{localTz} timezone:
					</label>
					<div>
						{time.localTz && time.localTz.from} -{' '}
						{time.localTz && time.localTz.to}
					</div>
					<label className="text-head text-uppercase text-logan small mt-2">
						{defaultTz} timezone:
					</label>
					<div>
						{time.defTz && time.defTz.from} -{' '}
						{time.defTz && time.defTz.to}
					</div>
				</div>
			</div>
			{error && <div className="small text-danger mt-2">{error}</div>}
		</div>
	);
};

export default TimeRangePicker;
