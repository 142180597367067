import actions from './actions';
export const clearErrors = () => {
	return (dispatch) => dispatch(actions.clearErrors());
};

export const clearResponse = () => {
	return (dispatch) => dispatch(actions.clearResponse());
};

export const clearAll = () => {
	return (dispatch) => dispatch(actions.clearAll());
};
