import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  statusStart,
  rejectImageRequest,
  acceptImageRequest,
  finishImageRequest,
  getMission,
  countTime,
  countTimeTask,
  setTimeCount,
  setPlusTime,
  timeOutTask,
  getDamages,
  clearlistDamages,
  getDamagesTire,
  clearDataTask,
  clearError,
  changeDoingTask,
  clearMessageTask,
  getlistTrims,
  handleChangeUrlAnition,
} from './../operations';
import ModalShowErr from './components/ModalShowErr';
import DamageView from './components/DamageView';
import AcceptButton from './components/AcceptButton';
import Inspect from './components/Inspect';
import ModalCheck from './components/ModalCheck';
import ModalImage from './components/ModalImage';
import ModalWatting from './components/ModalWatting';
import ModalTimeOut from './components/ModalTimeOut';
import TitleInspect from './components/TitleInspect';
import InfoBar from './components/InfoBar';
import PhotoThumb from './components/PhotoThumb';
import PhotoFull from './components/PhotoFull';
import RejectButton from './components/RejectButton';
import { logout } from '../../auth/operations';
import $ from 'jquery';
import Modal from './components/Modal';
import Animation from './components/Animation';
import ModalSupportFull from './components/ModalSupportFull';
import FinishButton from './components/FinishButton';
import ModalCheckSameInspection from './components/ModalCheckSameInspection';
window.$ = $;

const Process = (props) => {
  const [showImgInspect, setshowImgInspect] = useState(false);
  const [imgW, setimgW] = useState(0);
  const [imgH, setimgH] = useState(0);
  const [img, setimg] = useState();
  const [showBtn, setshowBtn] = useState({
    accept: false,
    finish: false,
    reject: false,
  });
  const [showInspectInfo, setshowInspectInfo] = useState(false);
  const [showmodalErr, setshowmodalErr] = useState(false);
  const [showmodalPriority, setshowmodalPriority] = useState(false);
  const [cagePo, setcagePo] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [showSvg, setshowSvg] = useState(false);
  const [idDamage, setidDamage] = useState({
    x: 0,
    y: 0,
  });
  const [btnConfirm, setbtnConfirm] = useState({
    title: '',
    name: '',
    des: '',
  });
  const [messErr, setmessErr] = useState('');
  const [messWarning, setmessWarning] = useState('');
  const [showWarning, setshowWarning] = useState(false);
  const [showConfirm, setshowConfirm] = useState(false);
  const [waitting, setwaitting] = useState(false);
  let [dataInspect, setdataInspect] = useState(null);
  let [damages, setdamages] = useState([]);
  const [imgSupport, setimgSupport] = useState([]);
  const [support, setsupport] = useState({ support: '', index: 0 });
  const [modalImageFull, setmodalImageFull] = useState(false);
  const [modalImage, setmodalImage] = useState(false);
  const [modalCheckSameInspection, setmodalCheckSameInspection] =
    useState(false);
  const [rotate, setrotate] = useState(0);
  const [zoom, setzoom] = useState(1);
  const [scale, setscale] = useState(1);
  const [show, setshow] = useState(false);
  const [cageShow, setcageShow] = useState(true);
  const [checkFinish, setcheckFinish] = useState(false);
  const [disableDragging, setdisableDragging] = useState(false);
  const [modalTimeOut, setmodalTimeOut] = useState(false);
  const [imgDone, setimgDone] = useState(false);
  const [checkTest, setcheckTest] = useState(false);
  const [errorVin, seterrorVin] = useState(false);
  const [autoFin, setautoFin] = useState(false);
  let { inspection, auth } = props;
  let { task } = inspection;
  let taskSlot = task.taskSlot ? task.taskSlot : {};
  const sameTask = task.sameTask ? task.sameTask : null;
  const sametaskSlot = task.sametaskSlot ? task.sametaskSlot : null;
  let userName =
    auth && auth.user && auth.user.username ? auth.user.username : null;
  const idTaskSlot = taskSlot._id ? taskSlot._id : null;

  const taskType = taskSlot.type ? taskSlot.type : null;

  const taskInspect = task.task ? task.task : {};
  const idTask = taskInspect._id ? taskInspect._id : null;
  const doingTask = inspection.doingTask ? inspection.doingTask : false;
  const inspectionInspect = task.inspection ? task.inspection : {};
  const theme = inspectionInspect.inspectionThemes
    ? inspectionInspect.inspectionThemes
    : {};

  const taskSetting = task && task.taskSetting ? task.taskSetting : {};
  const themeColor =
    inspectionInspect &&
    inspectionInspect.inspectionThemes &&
    inspectionInspect.inspectionThemes.themeColor
      ? inspectionInspect.inspectionThemes.themeColor
      : '';
  const options =
    taskInspect && taskInspect.options ? taskInspect.options : null;
  const checkTimeOut =
    inspection.timeOut && inspection.timeOut.success
      ? inspection.timeOut.success
      : false;

  const vehicle = task.vehicle ? task.vehicle : null;
  const cageSvg = task.cage ? task.cage : null;
  const inspectName =
    taskSlot && taskSlot.inspects ? taskSlot.inspects.name : null;
  const error =
    inspection &&
    inspection.error &&
    inspection.error.response &&
    inspection.error.response.data
      ? inspection.error.response.data.success
      : null;
  const unauthorized =
    inspection &&
    inspection.error &&
    inspection.error.response &&
    inspection.error.response.data
      ? inspection.error.response.data
      : null;
  const errorCode =
    inspection &&
    inspection.error &&
    inspection.error.response &&
    inspection.error.response.data &&
    inspection.error.response.data.code
      ? inspection.error.response.data.code
      : '';
  const mess =
    inspection &&
    inspection.error &&
    inspection.error.response &&
    inspection.error.response.data &&
    inspection.error.response.data.message
      ? inspection.error.response.data.message
      : '??????';
  let timer;
  let timeCount = inspection.timeCount;
  let plusTime = inspection.plusTime;
  let listDamages = inspection.listDamages;
  const sessionId =
    inspectionInspect && inspectionInspect.sessionID
      ? inspectionInspect.sessionID
      : null;
  const skipVin =
    inspectionInspect &&
    inspectionInspect.options &&
    inspectionInspect.options.customized &&
    inspectionInspect.options.customized.skip_vin
      ? inspectionInspect.options.customized.skip_vin
      : null;
  const usecase =
    inspectionInspect &&
    inspectionInspect.options &&
    inspectionInspect.options.usecase
      ? inspectionInspect.options.usecase
      : null;
  const decodeFailed =
    usecase === 'holman' && options && options.decodeFailed
      ? options.decodeFailed
      : false;
  useEffect(() => {
    let searchTask;
    const elementMenu = document.getElementById('Navbar');
    if (elementMenu) {
      elementMenu.classList.add('display-none');
    }
    if (!doingTask) {
      searchTask = setInterval(() => {
        props.getMission();
      }, 4000);
      if (idTaskSlot) {
        clearInterval(searchTask);
        if (task.message !== 'Success!' && checkTimeOut === false) {
          const time =
            taskSlot && taskSlot.inspects && taskSlot.inspects.time
              ? parseInt(taskSlot.inspects.time)
              : 60;
          props.setTimeCount(time);
        }
        const role =
          auth && auth.user && auth.user.role ? auth.user.role : 'AGENT';
        const fullScreen = process.env.REACT_APP_FULLSCREEN || 'ON';
        if (fullScreen === 'ON' && role === 'AGENT') {
          if (
            (document.fullScreenElement &&
              document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)
          ) {
            if (document.documentElement.requestFullScreen) {
              document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
              document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
              document.documentElement.webkitRequestFullScreen(
                Element.ALLOW_KEYBOARD_INPUT,
              );
            }
          }
        }
        notify();
        props.changeDoingTask();
      }
    }
    return () => {
      clearInterval(searchTask);
      const elementMenu = document.getElementById('Navbar');
      if (elementMenu) {
        elementMenu.classList.remove('display-none');
      }
    };
  }, [idTaskSlot, doingTask]);
  useEffect(() => {
    if (doingTask) {
      const vin = vehicle && vehicle.vin ? vehicle.vin : null;
      const vinTest = [
        '3N1AB7AP2DL768755',
        '1N6AD0EV1KN708290',
        '3N1BC1CP5CL378775',
        '1FMCU9GX0GUA46967',
        '5FNRL5H41GB510100',
        '1FA6P8CF3J5157777',
        '1GKS2GEJ6CR214475',
        '2GKALSEK2D6365573',
        '02D55604',
      ];
      const checkSessionTest =
        sessionId && sessionId.startsWith('T')
          ? sessionId.startsWith('T')
          : null;

      if (checkSessionTest || vinTest.includes(vin)) {
        setcheckTest(true);
      }
      if (taskType === 'QC') {
        setshowBtn({
          accept: true,
          reject: true,
          finish: false,
        });
        if (!['vin', 'qc_plate'].includes(inspectName)) {
          setshowInspectInfo(false);
        }
        if (taskSlot.photoCode === '04' && skipVin) {
          setshowInspectInfo(true);
        }
      } else {
        setshowInspectInfo(true);
        setshowBtn({
          accept: false,
          reject: false,
          finish: true,
        });
      }
    }
  }, [doingTask]);
  useEffect(() => {
    if (doingTask && imgDone) {
      if (
        checkFinish ||
        ['freezetime.tester', 'freezetime.1', 'freezetime.2'].includes(userName)
      ) {
        clearInterval(timer);
      } else {
        timer = setInterval(() => {
          props.countTime();
        }, 1000);
      }
      const warning = document.getElementById('count');
      if (timeCount <= 2) {
        setshowConfirm(false);
        setshowBtn({
          accept: false,
          finish: false,
          reject: false,
        });
      }
      if (timeCount === 10 && warning) {
        warning.classList.add('red');
      }
      if (timeCount === 0) {
        if (taskType === 'QC') {
          const data = {};
          props.timeOutTask(idTaskSlot, data);
          setwaitting(true);
        } else {
          if (!autoFin && !task.auto) {
            finishImage(true);
          }
        }
        clearInterval(timer);
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeCount, imgDone, checkFinish, doingTask]);
  useEffect(async () => {
    if (doingTask) {
      loadImage();
      const imgSupport =
        taskSlot.options && taskSlot.options.supportView
          ? taskSlot.options.supportView
          : false;
      setimgSupport(imgSupport);
      if (checkTimeOut && taskType === 'QC') {
        setwaitting(false);
        setmodalTimeOut(true);
      }
      window.addEventListener('contextmenu', disableRightClick);
    }
    if (['vin', 'qc_plate'].includes(inspectName)) {
      if (vehicle && vehicle.vin) {
        setdataInspect({ vin: vehicle.vin });
      }
      setshowInspectInfo(true);

      if (task.message === 'Success!') {
        setwaitting(false);
        clearInspect();
        await props.clearDataTask();
      } else if (error === false) {
        setshow(true);
        setwaitting(false);
        if (usecase === 'holman') {
          setshowBtn({
            accept: true,
            reject: false,
            finish: false,
          });
        }
      } else if (
        unauthorized === 'Unauthorized' ||
        ['SESSION_EXPIRED', 'KICK_AGENT', 'SHIFT_EXPIRED'].includes(errorCode)
      ) {
        setmessErr(mess);
        setwaitting(false);
        setshowmodalErr(true);
        setshowBtn({
          accept: false,
          reject: false,
          finish: false,
        });
      }
    } else {
      if (task.message === 'Success!') {
        setshowBtn({
          accept: false,
          reject: false,
          finish: false,
        });
        if (task.auto) {
          setwaitting(false);
          setmodalTimeOut(true);
        } else {
          setwaitting(false);
          clearInspect();
          await props.clearDataTask();
        }
      } else if (
        unauthorized === 'Unauthorized' ||
        ['SESSION_EXPIRED', 'KICK_AGENT', 'SHIFT_EXPIRED'].includes(errorCode)
      ) {
        const mess =
          inspection &&
          inspection.error &&
          inspection.error.response &&
          inspection.error.response.data &&
          inspection.error.response.data.message
            ? inspection.error.response.data.message
            : '??????';
        setmessErr(mess);
        setwaitting(false);
        setshowBtn({
          accept: false,
          reject: false,
          finish: false,
        });
        setshowmodalErr(true);
      } else if (['QC-PASSED'].includes(errorCode)) {
        setwaitting(false);
        setshowWarning(true);
        setmessWarning(mess);
        setcheckFinish(false);
      }
    }
    return () => {
      window.removeEventListener('contextmenu', disableRightClick);
    };
  }, [
    inspection.error,
    task.message,
    unauthorized,
    checkTimeOut,
    errorCode,
    doingTask,
  ]);
  const disableRightClick = (e) => {
    e.preventDefault();
  };
  let clearInspect = () => {
    setdamages([]);
    setcagePo({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    });
    setshowImgInspect(false);
    setdataInspect({});
    setimg({
      imgWebp: '',
      imgReal: '',
    });
    setshowInspectInfo(false);
    setcheckTest(false);
    setrotate(0);
    setcheckFinish(false);
    setmodalImageFull(false);
    setshowmodalPriority(false);
    setimgDone(false);
    setautoFin(false);
    const warning = document.getElementById('count');
    if (warning) {
      warning.classList.remove('red');
    }
  };
  let calShowCage = () => {
    //e.preventDefault();
    if (
      options &&
      options.cageInfo &&
      parseInt(options.cageInfo.cage.width, 10) > 0
    ) {
      loadSetCage();
    } else {
      loadSvg();
    }
  };
  let onReload = () => {
    props.getMission(idTaskSlot);
  };
  let onZoomChange = (zoom) => {
    setzoom(zoom);
  };
  //
  const showButtonReject = () => {
    if (showBtn && showBtn.reject) {
      setshowBtn({
        accept: false,
        reject: false,
        finish: true,
      });
    } else {
      setshowBtn({
        accept: false,
        reject: true,
        finish: true,
      });
    }
  };
  //Accetp and Reject The Image
  let acceptImage = () => {
    let data = {};
    setshowConfirm(false);
    let offsetWidth = $('.App-zoom').width();
    let offsetHeight = $('.App-zoom').height();
    const decode =
      inspectionInspect &&
      inspectionInspect.options &&
      inspectionInspect.options.decode
        ? inspectionInspect.options.decode
        : '';
    if (inspectName === 'vin' && dataInspect && !dataInspect.createVehicle) {
      const vin = dataInspect.vin || '';
      if (
        (vin.includes('O') ||
          vin.includes('I') ||
          vin.includes('Q') ||
          vin.includes('o') ||
          vin.includes('i') ||
          vin.includes('q') ||
          vin.length <= 16) &&
        decode !== 'none' &&
        usecase !== 'holman'
      ) {
        seterrorVin(true);
        document.getElementById('vin').classList.add('is-invalid');
        return;
      }
      seterrorVin(false);
      document.getElementById('vin').classList.remove('is-invalid');
      data = { ...dataInspect, message: btnConfirm.name };
    } else if (
      inspectName === 'vin' &&
      dataInspect &&
      dataInspect.createVehicle
    ) {
      const checkDataInput = ['vin', 'body_type'];
      let checkinValid = checkDataInput.filter((item) => !dataInspect[item]);
      let checkValid = checkDataInput.filter((item) => dataInspect[item]);
      if (usecase !== 'holman' && checkValid && checkValid.length > 0) {
        for (let i = 0; i < checkValid.length; i++) {
          document.getElementById(checkValid[i]).classList.remove('is-invalid');
        }
      }
      if (usecase !== 'holman' && checkinValid && checkinValid.length > 0) {
        for (let i = 0; i < checkinValid.length; i++) {
          document.getElementById(checkinValid[i]).classList.add('is-invalid');
        }
        return;
      }
      data = { ...dataInspect };
      data['make'] = data['make'] ? data['make'] : 'Not Decoded';
      data['model'] = data['model'] ? data['model'] : 'Not Decoded';
    } else if (taskSetting.type === 'exteriorView') {
      if (skipVin && taskSlot.photoCode === '04') {
        data = { ...dataInspect };
      }

      data['message'] = btnConfirm.name;
      data['cageInfo'] = {
        original_image: {
          width: imgW,
          height: imgH,
        },
        actual_image: {
          width: offsetWidth,
          height: offsetHeight,
        },
        cage: {
          width: parseInt(cagePo.width, 10),
          height: parseInt(cagePo.height, 10),
          x: parseInt(cagePo.x, 10),
          y: parseInt(cagePo.y, 10),
        },
        cageSvg: cageSvg,
      };
    } else {
      data['message'] = btnConfirm.name;
    }
    if (inspectName === 'qc_plate') {
      document.getElementById('plate_number').classList.remove('is-invalid');
      const dataPlateNumber =
        dataInspect && dataInspect.plate_number ? dataInspect.plate_number : '';
      if (!dataPlateNumber) {
        document.getElementById('plate_number').classList.add('is-invalid');
        return;
      }
      data = { ...data, ...dataInspect, message: btnConfirm.name };
    }
    setcheckFinish(true);
    setwaitting(true);
    props.acceptImageRequest(data, idTaskSlot);
  };
  let rejectImage = () => {
    const data = {};
    data['message'] = btnConfirm.name;
    data['from'] = taskType && taskType === 'QC' ? 'QC' : 'INSPECT';
    clearInterval(timer);
    setcheckFinish(true);
    setwaitting(true);
    setshowConfirm(false);
    props.rejectImageRequest(data, idTask);
  };
  //FinishImage
  let finishImage = (auto) => {
    if (!task) return false;
    if (auto) {
      setautoFin(true);
    }
    const tableDamage =
      inspectionInspect &&
      inspectionInspect.options &&
      inspectionInspect.options.damage_table
        ? inspectionInspect.options.damage_table
        : null;
    setcheckFinish(true);
    setshowConfirm(false);
    setwaitting(true);
    $('.btn-reset').trigger('click');
    let data = { inspectData: dataInspect ? { ...dataInspect } : null };

    if (damages && damages.length > 0) {
      let inspectDamages = [];
      for (let i = 0; i < damages.length; i++) {
        if (damages[i].component && damages[i].damage_name) {
          inspectDamages.push({
            original_image: {
              width: imgW,
              height: imgH,
            },
            ...damages[i],
            label: damages[i].des || damages[i].description || damages[i].label,
            description:
              tableDamage === 'autograde' && damages[i].condition
                ? damages[i].condition
                : damages[i].description
                ? damages[i].description
                : '',
          });
        }
        if (!auto && damages[i].component && !damages[i].damage_name) {
          setmessWarning('Please Select Component Or Damage');
          setcheckFinish(false);
          setwaitting(false);
          setshowWarning(true);
          return;
        }
      }
      data['detected_damages'] = inspectDamages;
    }
    data['plusTime'] = plusTime ? plusTime : false;
    data['autoFinish'] = auto ? auto : false;
    props.finishImageRequest(data, idTaskSlot);
  };
  //
  let setScaleDown = () => {
    setdisableDragging(true);
    setscale(4);
  };
  let setScaleUp = () => {
    $('.btn-reset').trigger('click');
    setdisableDragging(false);
    setscale(1);
  };
  let handlePlusTime = () => {
    timeCount = timeCount + 30;
    props.setPlusTime();
    props.setTimeCount(timeCount);
  };
  // RND
  let resizeTop = (width, height, x, y) => {
    if (x && y) {
      setcagePo({
        x: x,
        y: y,
        width: parseInt(width, 10),
        height: parseInt(height, 10),
      });
    } else {
      setcagePo({
        ...cagePo,
        width: parseInt(width, 10),
        height: parseInt(height, 10),
      });
    }
  };
  let resizeXY = (cagePos) => {
    setcagePo({ ...cagePo, x: cagePos.x, y: cagePos.y });
  };
  //Damges
  let addDamages = (damages) => {
    setdamages([...damages]);
  };
  let updateDamage = (damages) => {
    setdamages([...damages]);
  };
  let deleteDamage = (damages) => {
    setdamages([...damages]);
  };
  //SVG LOAD
  //
  const savePointDamage = (x, y) => {
    setidDamage({
      x: x,
      y: y,
    });
  };
  //
  let OnOfSvg = (keyName) => {
    if (keyName === 'q' && cageShow === true) {
      svgOff();
      setcageShow(!cageShow);
    } else if (keyName === 'q' && cageShow === false) {
      svgDefault();
      setcageShow(!cageShow);
    }
  };
  let svgDefault = () => {
    const pathId = $('path');
    $('svg image').css('opacity', 0.7);
    let arrPathId = [];
    for (let i = 0; i < pathId.length; i++) {
      arrPathId.push(pathId[i].id);
    }
    for (let i = 0; i < arrPathId.length; i++) {
      if (arrPathId[i]) {
        $('#' + arrPathId[i]).css('opacity', 0.5);
      }
    }
  };
  let svgOff = () => {
    const pathId = $('path');
    $('svg image').css('opacity', 0);
    let arrPathId = [];
    for (let i = 0; i < pathId.length; i++) {
      arrPathId.push(pathId[i].id);
    }
    for (let i = 0; i < arrPathId.length; i++) {
      if (arrPathId[i]) {
        $('#' + arrPathId[i]).css('opacity', 0);
      }
    }
  };
  const handleLogout = () => {
    clearInspect();
    props.logout();
  };
  const handleBackToStart = () => {
    clearInspect();
    props.statusStart();
  };
  let loadImage = () => {
    let imgFull = (taskInspect && taskInspect.image_cap) || false;
    const imgLoading = new Image();
    imgLoading.src = imgFull;
    setimg(`${imgFull}?v=${Date.now()}`);
    if (imgFull) {
      imgLoading.onload = () => {
        setimgW(imgLoading.width);
        setimgH(imgLoading.height);
        setshowImgInspect(true);
        setimgDone(true);
      };
    }
  };
  const loadSvg = () => {
    let svg = document.getElementsByClassName('svg svg');
    if (svg[0]) {
      let svgWidth = parseInt(svg[0].getAttribute('width'), 10);
      let svgHeight = parseInt(svg[0].getAttribute('height'), 10);
      const offsetWidth = $('.App-zoom').width();
      const offsetHeight = $('.App-zoom').height();
      let y = 0;
      let x = 0;
      let distance = 0;
      if (
        taskSlot.photoCode === '04' ||
        taskSlot.photoCode === '07' ||
        taskSlot.photoCode === '10' ||
        taskSlot.photoCode === '11' ||
        taskSlot.photoCode === '12' ||
        taskSlot.photoCode === '13'
      ) {
        if (offsetWidth > svgWidth) {
          distance = offsetWidth / svgWidth;
        } else {
          distance = svgWidth / offsetWidth;
        }
        y = 85;
      } else {
        if (offsetHeight > svgHeight) {
          distance = offsetHeight / svgHeight;
        } else {
          distance = svgHeight / offsetHeight;
        }
        x = 160;
      }
      svgWidth = Math.floor(svgWidth * distance);
      svgHeight = Math.floor(svgHeight * distance);
      setcagePo({ x: x, y: y, width: svgWidth, height: svgHeight });
    }
  };
  const loadSetCage = () => {
    const offsetWidth = $('.App-zoom').width();
    const actualImage =
      options && options.cageInfo && options.cageInfo.actual_image.width
        ? options.cageInfo.actual_image.width
        : 0;
    let ratio = 0;
    let cageW =
      options &&
      options.cageInfo &&
      options.cageInfo.cage &&
      options.cageInfo.cage.width
        ? parseInt(options.cageInfo.cage.width, 10)
        : 0;
    let cageH =
      options &&
      options.cageInfo &&
      options.cageInfo.cage &&
      options.cageInfo.cage.height
        ? parseInt(options.cageInfo.cage.height, 10)
        : 0;
    let cageX =
      options &&
      options.cageInfo &&
      options.cageInfo.cage &&
      options.cageInfo.cage.x
        ? parseInt(options.cageInfo.cage.x, 10)
        : 0;
    let cageY =
      options &&
      options.cageInfo &&
      options.cageInfo.cage &&
      options.cageInfo.cage.y
        ? parseInt(options.cageInfo.cage.y, 10)
        : 0;
    if (offsetWidth < actualImage) {
      ratio = actualImage / offsetWidth;
      cageW = cageW / ratio;
      cageH = cageH / ratio;
      cageX = cageX / ratio;
      cageY = cageY / ratio;
    } else {
      ratio = offsetWidth / actualImage;
      cageW = cageW * ratio;
      cageH = cageH * ratio;
      cageX = cageX * ratio;
      cageY = cageY * ratio;
    }
    setcagePo({ x: cageX, y: cageY, width: cageW, height: cageH });
  };
  const showModalBtn = (title, name, des) => {
    setshowConfirm(true);
    setbtnConfirm({
      title: title,
      name: name,
      des: des,
    });
  };
  let notify = () => {
    const notification = new Notification('TASK', {
      icon: '/img/logo_blue.png',
      body: 'Now!! You are having Task',
    });
    return notification;
  };
  let onModalImage = (item, index) => {
    setsupport({ support: item, index: index });
    setmodalImage(true);
  };
  let offModalImage = () => {
    setsupport({ support: '', index: 0 });
    setmodalImage(false);
  };
  const start = () => {
    const data = {};
    data['status'] = 'Online';
    props.statusStart(data);
  };
  const closeWarning = () => {
    setshowWarning();
    props.clearError();
  };
  return (
    <Fragment>
      <Animation
        inspection={inspection}
        doingTask={doingTask}
        statusStart={start}
        showmodalErr={showmodalErr}
        handleChangeUrlAnition={props.handleChangeUrlAnition}
        showmodalPriority={showmodalPriority}
        messErr={messErr}
        logOut={props.logout}
      />
      <div
        className="Actions"
        style={
          checkTest
            ? {
                background: '#ffc107',
                dispplay: doingTask ? '' : 'none',
                opacity: doingTask ? '1' : '0.4',
                transition: '1s',
              }
            : {
                background: `${themeColor}`,
                dispplay: doingTask ? '' : 'none',
                opacity: doingTask ? '1' : '0.4',
                transition: '1s',
              }
        }
      >
        <div className="row">
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9"
            style={{ paddingLeft: '0' }}
          >
            {/* Check Photo Full and Thumb */}
            <div className="VehiclePhoto">
              {!showImgInspect ? (
                <PhotoThumb taskInspect={taskInspect} checkTest={checkTest} />
              ) : (
                <PhotoFull
                  taskType={taskType}
                  countTimer={timeCount >= 0 ? timeCount : 99}
                  disableDragging={disableDragging}
                  taskInspect={taskInspect}
                  inspectionInspect={inspectionInspect}
                  taskSlot={taskSlot}
                  img={img}
                  vehicle={vehicle}
                  svg={cageSvg}
                  cagePo={cagePo}
                  damages={damages}
                  showSvg={showSvg}
                  setshowSvg={() => setshowSvg(true)}
                  setidDamage={(x, y) => savePointDamage(x, y)}
                  idDamage={idDamage}
                  rotate={rotate}
                  zoom={zoom}
                  scale={scale}
                  setScaleDown={setScaleDown}
                  setScaleUp={setScaleUp}
                  resizeTop={(width, height) => resizeTop(width, height)}
                  imgSupport={imgSupport}
                  inspectName={inspectName}
                  resizeXY={(cagePos) => resizeXY(cagePos)}
                  OnOfSvg={OnOfSvg}
                  addDamages={(damages) => addDamages(damages)}
                  updateDamage={(damages) => updateDamage(damages)}
                  deleteDamage={(damages) => deleteDamage(damages)}
                  setRotate={() => setrotate(rotate + 90)}
                  onZoomChange={(zoom) => onZoomChange(zoom)}
                  onReload={onReload}
                  calShowCage={calShowCage}
                  onModalImage={onModalImage}
                  offModalImage={offModalImage}
                  onModalImageFull={() => setmodalImageFull(true)}
                  offModalImageFull={() => setmodalImageFull(false)}
                  onModalCheckSameInspection={() =>
                    setmodalCheckSameInspection(true)
                  }
                  offModalCheckSameInspection={() =>
                    setmodalCheckSameInspection(false)
                  }
                  plusTime={plusTime}
                  handlePlusTime={handlePlusTime}
                  checkTest={checkTest}
                  getDamages={props.getDamages}
                  listDamages={listDamages}
                  clearlistDamages={props.clearlistDamages}
                  dataInspect={dataInspect}
                />
              )}
            </div>
          </div>
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3"
            style={{
              background: '#e9e9ed',
              borderRadius: '5px',
            }}
          >
            {/* Inspect Photo */}
            <div className="inspect-photo">
              {/* Damage View */}
              <TitleInspect
                taskSlot={taskSlot}
                taskSettings={taskSetting}
                inspectName={inspectName}
              />
              <InfoBar
                vehicle={vehicle}
                plusTime={plusTime}
                taskInspect={taskInspect}
                inspectionInspect={inspectionInspect}
                taskSlot={taskSlot}
                imgSupport={imgSupport}
                support={support}
                auth={auth}
                inspectName={inspectName}
              />
              {taskType === 'INSPECT' ? (
                <div className="mb-1 d-flex">
                  {showBtn && showBtn.finish && (
                    <FinishButton
                      showModalBtn={(title, name, des) =>
                        showModalBtn(title, name, des)
                      }
                    />
                  )}
                  <button
                    type="button"
                    className="btn btn-danger d-inline"
                    style={{ width: '10%' }}
                    onClick={() => showButtonReject()}
                  >
                    {showBtn && showBtn.reject ? (
                      <i className="fas fa-angle-down"></i>
                    ) : (
                      <i className="fas fa-times"></i>
                    )}
                  </button>
                </div>
              ) : null}

              <div className="form-group checkAcp">
                {showInspectInfo && (
                  <Inspect
                    getDamages={props.getDamages}
                    getlistTrims={props.getlistTrims}
                    dataInspect={dataInspect}
                    updateDataInspect={setdataInspect}
                    inspectionInspect={inspectionInspect}
                    errorVin={errorVin}
                    vehicle={vehicle}
                    taskSlot={taskSlot}
                    taskInspect={taskInspect}
                    getDamagesTire={props.getDamagesTire}
                    sessionId={sessionId}
                    listDamages={listDamages}
                    updateDamage={(damages) => updateDamage(damages)}
                    damages={damages}
                  />
                )}
                {showBtn && showBtn.accept && (
                  <AcceptButton
                    taskSettings={taskSetting}
                    showModalBtn={(title, name, des) =>
                      showModalBtn(title, name, des)
                    }
                  />
                )}
                {!decodeFailed && showBtn && showBtn.reject && (
                  <RejectButton
                    taskSlot={taskSlot}
                    taskInspect={taskInspect}
                    inspectName={inspectName}
                    inspectionInspect={inspectionInspect}
                    taskSettings={taskSetting}
                    showModalBtn={(title, name, des) =>
                      showModalBtn(title, name, des)
                    }
                    photoCode={taskSlot.photoCode}
                    theme={theme}
                  />
                )}
                {waitting ? <ModalWatting /> : null}
                {modalCheckSameInspection ? (
                  <ModalCheckSameInspection
                    task={sameTask}
                    taskSlot={sametaskSlot}
                    vehicle={vehicle}
                    hideModal={() => setmodalCheckSameInspection(false)}
                  />
                ) : null}
                {modalTimeOut ? (
                  <ModalTimeOut timeOut={() => handleBackToStart()} />
                ) : null}
                {modalImage ? <ModalImage support={support} /> : null}
                {modalImageFull ? (
                  <ModalSupportFull
                    src={imgSupport}
                    photoCode={taskSlot.photoCode}
                    inspectName={inspectName}
                  />
                ) : null}
                {show ? (
                  <ModalCheck
                    task={task}
                    error={error}
                    description="/img/gif.gif"
                    taskSlot={taskSlot}
                    clickOff={() => setshow('')}
                    taskInspect={taskInspect}
                  />
                ) : null}
                {showConfirm ? (
                  <Modal
                    clickFinish={() => finishImage()}
                    clickAccept={() => acceptImage()}
                    clickReject={() => rejectImage()}
                    title={btnConfirm.title}
                    name={btnConfirm.name}
                    description={btnConfirm.des}
                    clickNo={() => setshowConfirm(false)}
                  />
                ) : null}
                {showmodalErr ? (
                  <ModalShowErr
                    messErr={messErr}
                    resetError={() => handleLogout()}
                  />
                ) : null}
                {showWarning ? (
                  <ModalShowErr
                    messErr={messWarning}
                    resetError={() => closeWarning()}
                  />
                ) : null}
              </div>
              {!['tire', 'interior'].includes(inspectName) ? (
                <DamageView
                  showSvg={showSvg}
                  idDamage={idDamage}
                  svg={cageSvg}
                  setshowSvg={() => setshowSvg(false)}
                  inspectionInspect={inspectionInspect}
                  vehicle={vehicle}
                  addDamages={(damages) => addDamages(damages)}
                  updateDamage={(damages) => updateDamage(damages)}
                  deleteDamage={(damages) => deleteDamage(damages)}
                  damages={damages}
                  taskSlot={taskSlot}
                  taskInspect={taskInspect}
                  listDamages={listDamages}
                  getDamages={props.getDamages}
                  inspectName={inspectName}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  inspection: state.inspection,
});

export default connect(mapStateToProps, {
  statusStart,
  rejectImageRequest,
  acceptImageRequest,
  finishImageRequest,
  getMission,
  logout,
  countTime,
  countTimeTask,
  setTimeCount,
  setPlusTime,
  timeOutTask,
  getDamages,
  clearlistDamages,
  getDamagesTire,
  clearDataTask,
  clearError,
  changeDoingTask,
  clearMessageTask,
  getlistTrims,
  handleChangeUrlAnition,
})(Process);
