import React from 'react';
import PropTypes from 'prop-types';
import AccordionItem from '../widgets/AccordionItem';

const CageViewInfo = ({ data, show, onChangeStatus, parent, user }) => {
  let listStatus = [
    { label: 'REQUEST-REVIEW', class: 'btn-success' },
    { label: 'PROCESS-PHOTO', class: 'btn-success' },
    { label: 'PROCESS-CAGE', class: 'btn-success' },
    { label: 'PROCESS-LABEL', class: 'btn-success' },
  ];
  if (user.role !== 'AGENT') {
    listStatus = [
      { label: 'REQUEST-REVIEW', class: 'btn-success' },
      { label: 'ACTIVE', class: 'btn-success' },
      { label: 'CONFIRM', class: 'btn-success' },
      { label: 'ARCHIVE', class: 'btn-secondary' },
      { label: 'DRAFT', class: 'btn-info' },
      { label: 'PROCESS-PHOTO', class: 'btn-success' },
      { label: 'DELETE', class: 'btn-danger' },
    ];
  }

  const bodyContent = (
    <div id="Cage_options">
      <div className="row">
        <div className="col-6 col-lg-4">
          <h3 className="display-6 text-secondary">Actions</h3>
          <div className="btn-list">
            {listStatus.map((item, index) => {
              if (user.role === 'VIEWER') {
                return null;
              }
              const type = item;
              const btnKey = item + '-' + type + index;
              return (
                <button
                  key={btnKey}
                  className={`btn btn-sm ${type.class} mb-2`}
                  onClick={onChangeStatus.bind(this, type.label)}
                >
                  {type.label}
                </button>
              );
            })}
          </div>
        </div>
        <div className="col-6 col-lg-3">
          <h3 className="display-6 text-secondary">Status</h3>
          <div className="display-5 text-logan text-head">{data.status}</div>
        </div>
        <div className="col-6 col-lg-5">
          <h3 className="display-6 text-secondary">Auto Values</h3>
          <div className="badge-group">
            <div className="badge badge-secondary">
              Type: <b>{data.group}</b>
            </div>
            <div className="badge badge-secondary ml-2">
              Class: <b>{data.class}</b>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Make</h3>
          <div>{data.make}</div>
        </div>
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Model</h3>
          <div>{data.model}</div>
        </div>
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Year</h3>
          <div>{data.year}</div>
        </div>
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Body Type</h3>
          <div>{data.bodyType}</div>
        </div>
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Trim &amp; Configuration</h3>
          <div>{data.trim}</div>
        </div>
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Platform Code</h3>
          <div>{data.code}</div>
        </div>
      </div>
    </div>
  );
  return (
    <AccordionItem
      id="info"
      buttonLabel="Information"
      bodyContent={bodyContent}
      show={show}
      parent={parent}
    />
  );
};

CageViewInfo.propTypes = {
  data: PropTypes.object.isRequired,
};

CageViewInfo.defaultProps = {
  show: false,
};

export default CageViewInfo;
