import React from 'react';
import { uid } from '../../../../utils/string';

const Switch = React.memo(props => {
	const { name, value, isChecked, onChange } = props;
	const id = uid();
	return (
		<div className="custom-control custom-switch">
			<input
				name={name}
				type="checkbox"
				className="custom-control-input"
				id={id}
				onChange={onChange}
				value={value}
				defaultChecked={isChecked}
			/>
			<label className="custom-control-label" htmlFor={id}></label>
		</div>
	);
});

export default Switch;
