export const uid = () => {
	return (
		'_' +
		Math.random()
			.toString(36)
			.substr(2, 9)
	);
};

export const durationText = amount => {
	const hrs = Math.floor(amount / 3600);
	amount %= 3600;
	const mins = Math.floor(amount / 60);
	const secs = amount % 60;
	let text = '';
	if (hrs > 0) text += hrs + 'h ';
	if (mins > 0) text += mins + 'm ';
	if (secs > 0) text += secs + 's';
	return text;
};
