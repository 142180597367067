import React from 'react';
import $ from 'jquery';
window.$ = $;

const DamageView = (props) => {
  let { damages, inspectName, view, inspectionInspect, getDamages } = props;
  let deleteDamage = (id, e) => {
    props.setshowSvg();
    if (damages[id]) {
      damages.splice(id, 1);
      props.deleteDamage(damages);
    }
  };
  const callListDamages = (damages, id) => {
    const tableDamage =
      inspectionInspect &&
      inspectionInspect.options &&
      inspectionInspect.options.damage_table
        ? inspectionInspect.options.damage_table
        : null;
    if (tableDamage === 'bmw') {
      const item = {
        component: damages[id].component,
        damage_group: damages[id].damage_group,
        series_nme: damages[id].series_nme,
      };
      getDamages(item, tableDamage);
    } else {
      const damageSource =
        inspectionInspect &&
        inspectionInspect.options &&
        inspectionInspect.options.damages_source
          ? inspectionInspect.options.damages_source
          : false;
      let item = {
        component: damages[id].component,
        damage_group: damages[id].damage_group,
        material: damages[id].material ? damages[id].material : 'null',
      };
      if (damageSource) {
        item = { ...item, source: damageSource };
      }
      getDamages(item);
    }
  };
  let editDamage = (id) => {
    damages[id].damage_name = null;
    props.updateDamage(damages);
    callListDamages(damages, id);
  };
  let highlightDamage = (index) => {
    $('#rnd-' + index).addClass('heartBeat');
  };

  let dehighlightDamage = (index) => {
    $('#rnd-' + index).removeClass('heartBeat');
  };
  let showDamagesList = () => {
    let width = '450px';
    if (view) {
      width = '550px';
    }
    if (damages && damages.length > 0) {
      return (
        <div style={{ position: 'relative' }}>
          <div
            className="count-damamge"
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              top: '-15px',
              left: '-10px',
              zIndex: '99',
            }}
          >
            <span
              className="text-white"
              style={{
                background: '#321342',
                width: '25px',
                height: '25px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '20%',
                fontSize: '13px',
              }}
            >
              {damages.length}
            </span>
          </div>
          <div
            style={{
              height: damages.length > 6 ? width : '',
              overflowY: damages.length > 6 ? 'scroll' : '',
            }}
          >
            {damages.map((item, index) => {
              const damage = item;
              let action = '';
              switch (damage.admin_response) {
                case 'edit':
                  action = 'badge-primary';
                  break;
                case 'reject':
                  action = 'badge-danger';
                  break;
                case 'add':
                  action = 'badge-success';
                  break;
                default:
                  break;
              }
              return (
                <li
                  key={`DamageView_${index}`}
                  className="list-group-item mb-1"
                  onMouseEnter={() => highlightDamage(index)}
                  onMouseLeave={() => dehighlightDamage(index)}
                  style={{ borderRadius: '5px' }}
                >
                  {damage.admin_response && (
                    <div className="d-block mb-1">
                      <div className="d-flex">
                        <div
                          className={`badge ${action} text-head text-white text-uppercase mr-1`}
                        >
                          Admin : {damage.admin_response}
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    style={{ color: '#000' }}
                    className="badge badge-warning text-head mr-1"
                  >
                    {index + 1}
                  </div>
                  {damage.material ? (
                    <div
                      style={{ color: '#fff' }}
                      className="badge badge-primary text-head text-uppercase mr-1"
                    >
                      {damage.material === 'Painted' ? (
                        <i className="fas fa-fill-drip"></i>
                      ) : damage.material === 'Textured Surface' ? (
                        <i className="fas fa-recycle"></i>
                      ) : damage.material === 'Chrome' ? (
                        <i className="far fa-gem"></i>
                      ) : damage.material === 'Alloy Wheel' ? (
                        <i className="fas fa-drum-steelpan"></i>
                      ) : damage.material === 'Plastic Cover' ? (
                        <i className="fas fa-record-vinyl"></i>
                      ) : damage.material === 'Steel Wheel' ? (
                        <i className="fas fa-dumbbell"></i>
                      ) : damage.material === 'Glass' ? (
                        <i className="fas fa-glass-whiskey"></i>
                      ) : damage.material === 'Steel' ? (
                        <i className="fas fa-dharmachakra"></i>
                      ) : null}
                    </div>
                  ) : null}
                  {damage.damage_typeAgent ? (
                    <div
                      id={`damage-item-${index}`}
                      className="badge badge-warning text-head mr-1"
                    >
                      {damage.damage_typeAgent}[Agent]
                    </div>
                  ) : null}
                  <div
                    id={`list-item-${index}`}
                    className="badge badge-primary text-head mr-1"
                  >
                    {damage.component || 'Not yet defined'}
                  </div>
                  <div
                    id={`damage-item-${index}`}
                    className="badge badge-warning text-head mr-1"
                    style={{
                      color: damage.grade_score === 0 ? '#fff' : '#000',
                      background:
                        damage.grade_score === 0
                          ? 'grey'
                          : damage.grade_score === 1
                          ? 'yellow'
                          : damage.grade_score === 2
                          ? 'orange'
                          : damage.grade_score >= 3
                          ? 'red'
                          : '',
                    }}
                  >
                    {damage.damage_name || 'Not yet defined'}
                  </div>
                  {!['qc_tire', 'qc_interior', 'interior', 'tire'].includes(
                    inspectName,
                  ) && view !== true ? (
                    <div
                      className="btn-group"
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      {damage.damage_name ? (
                        <i
                          onClick={() => editDamage(index)}
                          className="fa fa-lg fa-edit text-warning mr-1"
                        />
                      ) : null}

                      <i
                        onClick={() => deleteDamage(index)}
                        className="fa fa-lg fa-times text-danger"
                      />
                    </div>
                  ) : null}
                </li>
              );
            })}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };
  return <div className="damage-view">{showDamagesList()}</div>;
};

export default DamageView;
