import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Pagination extends Component {
	render() {
		const { page, pages, size, action, total } = this.props;

		const listSize = [10, 20, 50];

		return (
			<nav aria-label="Page navigation" className="mt-2 row">
				<div className="mr-2">
					<ul className="pagination">
						<li
							className={`page-item ${
								page === 1 ? 'disabled' : ''
							}`}
						>
							<button
								className="page-link"
								onClick={action}
								aria-label="First"
								data-page={1}
							>
								<span aria-hidden="true">
									<i className="fa fa-lg fa-step-backward" />
								</span>
								<span className="sr-only">First</span>
							</button>
						</li>
						<li
							className={`page-item ${
								page === 1 ? 'disabled' : ''
							}`}
						>
							<button
								className="page-link"
								onClick={action}
								aria-label="Previous"
								data-page={page - 1}
							>
								<span aria-hidden="true">
									<i className="fa fa-lg fa-angle-left" />
								</span>
								<span className="sr-only">Previous</span>
							</button>
						</li>
						<li className={`page-item`}>
							<div className="input-group mb-0">
								<input
									id="Current_page"
									type="text"
									className="form-control"
									aria-label="Current_page"
									aria-describedby="Current_page"
									value={page}
									onChange={action}
								/>
								<div className="input-group-append">
									<span
										className="input-group-text"
										id="Total_pages"
									>
										of {pages} pages
									</span>
								</div>
							</div>
						</li>
						<li
							className={`page-item ${
								page === pages ? 'disabled' : ''
							}`}
						>
							<button
								className="page-link"
								onClick={action}
								aria-label="Next"
								data-page={page + 1}
							>
								<span aria-hidden="true">
									<i className="fa fa-lg fa-angle-right" />
								</span>
								<span className="sr-only">Next</span>
							</button>
						</li>
						<li
							className={`page-item ${
								page === pages ? 'disabled' : ''
							}`}
						>
							<button
								className="page-link"
								onClick={action}
								aria-label="Last"
								data-page={pages}
							>
								<span aria-hidden="true">
									<i className="fa fa-lg fa-step-forward" />
								</span>
								<span className="sr-only">Last</span>
							</button>
						</li>
					</ul>
				</div>
				<div className="text-center mr-2">
					<div
						className="btn-group btn-group-toggle"
						data-toggle="buttons"
					>
						{listSize.map((item) => (
							<label
								key={`Size_${item}`}
								className={`btn btn-normal btn-secondary ${
									size === item ? 'active' : ''
								}`}
								data-value={item}
								onClick={action}
							>
								<input
									type="radio"
									name="limit"
									id="Page_size"
									autoComplete="off"
									value={item}
									defaultChecked={size === item}
								/>{' '}
								{item}
							</label>
						))}
					</div>
				</div>
				{total ? (
					<div className="text-center">
						<div className="btn-group">
							<label className={`btn btn-small btn-secondary`}>
								{total}/ {size}
							</label>
						</div>
					</div>
				) : null}
			</nav>
		);
	}
}

Pagination.propTypes = {
	page: PropTypes.number.isRequired,
	pages: PropTypes.number.isRequired,
	total: PropTypes.number,
	action: PropTypes.func.isRequired,
};

export default Pagination;
