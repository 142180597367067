import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Profile } from './components';
import { changeProfile, clearResponse, clearErrors } from '../operations';
import SpinnerIcon from '../../../components/SpinnerIcon';

const Account = (props) => {
  //const [password, setpassword] = useState({});
  const { errors } = props;
  const { user, response } = props.auth;
  const [loading, setloading] = useState(false);
  // const onChange = (e) => {
  //   e.preventDefault();
  //   setpassword({ ...password, [e.target.name]: e.target.value });
  // };
  useEffect(() => {
    if (errors.data) {
      setloading(false);
    }
    if (response.status === 'success') {
      setloading(false);
      props.clearResponse();
      props.clearErrors();
    }
  }, [errors.data, response.status]);
  // const onChangeProfile = async (e) => {
  //   e.preventDefault();
  //   setloading(true);
  //   const data = {
  //     ...password,
  //   };
  //   props.changeProfile(data);
  //   setpassword({ oldPassword: '', newPassword: '' });
  // };
  return (
    <div id="Account" className="mt-4 slide-top">
      {loading ? (
        <div className={`modal waitting show-modal`} id="myModal">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-body text-center">
                <span>Pave Is Hard Working</span>
                <SpinnerIcon />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12">
            <Profile user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  changeProfile,
  clearResponse,
  clearErrors,
})(Account);
