import actions from './actions';
import axios from 'axios';

axios.defaults.baseURL =
	process.env.REACT_APP_AGENT_BASE_URL || 'https://agent.paveapi.com';

export const getAllCages = params => {
	return dispatch => {
		axios
			.get('/api/cages', { params: params })
			.then(res => {
				dispatch(actions.getAllCages(res.data));
			})
			.catch(e => dispatch(actions.hasError(e)));
	};
};

export const getCage = id => {
	return dispatch => {
		axios
			.get(`/api/cages/${id}`)
			.then(res => {
				dispatch(actions.getCage(res.data));
			})
			.catch(e => dispatch(actions.hasError(e)));
	};
};

export const getCageDetail = (id, action, cageId, history) => {
	let params = { action: action };
	return dispatch => {
		axios
			.get(`/api/cages/details/${id}`, { params: params })
			.then(res => {
				dispatch(actions.getCageDetail(res.data));
			})
			.catch(e => {
				dispatch(actions.hasError(e));
				history.push(`/cageView/${cageId}`);
			});
	};
};

export const postCageNote = (id, data) => {
	return dispatch => {
		axios
			.post(`/api/cages/${id}/notes`, data)
			.then(res => {
				dispatch(actions.postCageNote(res.data));
			})
			.catch(e => dispatch(actions.hasError(e)));
	};
};

export const putCageDetail = (id, putData) => {
	return dispatch => {
		axios
			.put(`/api/cages/details/${id}`, putData)
			.then(res => {
				dispatch(actions.putCageDetail(res.data));
			})
			.catch(e => dispatch(actions.hasError(e)));
	};
};

export const putCageLabel = (id, putData, history = false) => {
	return dispatch => {
		axios
			.put(`/api/cages/details/${id}`, putData)
			.then(res => {
				dispatch(actions.getCageDetail(res.data));
				if (history && putData.cageId) {
					history.push(`/cageView/${putData.cageId}`);
				}
			})
			.catch(e => dispatch(actions.hasError(e)));
	};
};

export const putCageStatus = (id, data) => {
	return dispatch => {
		axios
			.put(`/api/cages/${id}/status`, data)
			.then(res => {
				dispatch(actions.putCageStatus(res.data));
			})
			.catch(e => dispatch(actions.hasError(e)));
	};
};

export const uploadFile = (id, data, cageId) => {
	return dispatch => {
		axios
			.post(`/api/cages/details/upload/${id}`, data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(res => {
				dispatch(actions.uploadFile(res.data));
			})
			.catch(e => dispatch(actions.hasError(e)));
	};
};
