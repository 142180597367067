import React from "react";

export default () => {
  return (
    <div>
      <img
        src="/img/spinner.gif"
        style={{ width: "50px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    </div>
  );
};
