import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import DamageView from './DamageView';

window.$ = $;

const ModalCheckSameInspection = (props) => {
  const { task, taskSlot, hideModal, vehicle } = props;
  const vehData = vehicle && vehicle.data ? vehicle.data : {};
  let inspectData =
    taskSlot && taskSlot.inspectData ? taskSlot.inspectData : null;
  const src = task && task.image_cap ? task.image_cap : null;
  let damageNotGen =
    inspectData && inspectData.detected_damages
      ? inspectData.detected_damages
      : [];
  damageNotGen = damageNotGen.filter(
    (item) => item.admin_response !== 'reject',
  );
  let modalBodyStyle = { backgroundColor: '#EEE' };
  const [damagePoint, setdamagePoint] = useState([]);
  useEffect(() => {
    loadDamages();

    // eslint-disable-next-line
  }, []);

  const loadDamages = () => {
    let img = (task && task.image_cap) || false;
    const imgLoad = new Image();
    imgLoad.src = img;
    let arrayPoint = [];
    imgLoad.onload = () => {
      let offsetWidth = $(`#img-annotated`).width();
      let offsetHeight = $(`#img-annotated`).height();
      if (damageNotGen && damageNotGen.length > 0) {
        for (let i = 0; i < damageNotGen.length; i++) {
          const item = damageNotGen[i];
          const username = item.username ? item.username : null;
          const admin_response = item.admin_response
            ? item.admin_response
            : null;
          const user_response = item.user_response ? item.user_response : null;
          const grade_score = item.grade_score ? item.grade_score : 0;
          let strokeColor;
          if (grade_score === 0) {
            strokeColor = 'grey';
          } else if (grade_score === 1) {
            strokeColor = 'yellow';
          } else if (grade_score === 2) {
            strokeColor = 'orange';
          } else if (grade_score >= 3) {
            strokeColor = 'red';
          }
          const source = item.source ? item.source : null;
          const actualImageW =
            item.actual_image && item.actual_image.width
              ? item.actual_image.width
              : 0;
          const actualImageH =
            item.actual_image && item.actual_image.width
              ? item.actual_image.height
              : 0;
          let locaX =
            item.damage_location && item.damage_location.x
              ? item.damage_location.x
              : 0;
          let locaY =
            item.damage_location && item.damage_location.y
              ? item.damage_location.y
              : 0;
          let newLocaX = 0;
          let newLocaY = 0;
          const ratioX = offsetWidth / actualImageW;
          const ratioY = offsetHeight / actualImageH;
          newLocaX = Math.round(locaX * ratioX);
          newLocaY = Math.round(locaY * ratioY);

          arrayPoint.push({
            x: newLocaX + 10,
            y: newLocaY + 5,
            username: username,
            admin_response: admin_response,
            user_response: user_response,
            source: source,
            strokeColor: strokeColor,
          });
        }
      }
      setdamagePoint(arrayPoint);
    };
  };
  const findFromList = (list, key, value) => {
    if (list && list.length) {
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item[key] && item[key].id === parseInt(value, 10)) {
          return item[key];
        }
      }
    }
    return false;
  };
  const searchDataVehicle = (name, id) => {
    if (name === 'int_id') {
      const intColors = vehData.vehicle_int_colors || [];
      const intColor = findFromList(intColors, 'int_color', id);
      return (
        <div className="d-flex align-items-center">
          <div
            className="name mr-2"
            style={{
              textTransform: 'uppercase',
              padding: '5px 10px',
              background: '#321640',
              color: '#fff',
              borderRadius: '6px',
            }}
          >
            {name ? name.replace('_', ' ') : ''}
          </div>
          <div className="data">
            {intColor.color_name ? intColor.color_name : ''}(
            {intColor.color_code ? intColor.color_code : ''})
          </div>
        </div>
      );
    }
    if (name === 'ext_id') {
      const extColors = vehData.vehicle_ext_colors || [];
      const extColor = findFromList(extColors, 'ext_color', inspectData.ext_id);
      return (
        <div className="d-flex align-items-center">
          <div
            className="name mr-2"
            style={{
              textTransform: 'uppercase',
              padding: '5px 10px',
              background: '#321640',
              color: '#fff',
              borderRadius: '6px',
            }}
          >
            {name ? name.replace('_', ' ') : ''}
          </div>
          <div className="data mr-2">
            {extColor.generic_name ? extColor.generic_name : ''}(
            {extColor.color_code ? extColor.color_code : ''})
          </div>
          <div
            className="color"
            style={{
              width: '34px',
              height: '34px',
              border: '1px solid',
              background: `#${extColor.hex_value ? extColor.hex_value : ''}`,
              borderRadius: '50%',
            }}
          ></div>
        </div>
      );
    }
    if (name === 'trim_id') {
      const vehicleTrimPack = vehData.vehicle_trims_pack || [];
      const checkSource =
        vehicleTrimPack &&
        vehicleTrimPack.length &&
        vehicleTrimPack.some((item) => item.trim && item.trim.source);
      const trims = checkSource ? vehicleTrimPack : vehData.vehicle_trims;
      const trim = findFromList(trims, 'trim', inspectData.trim_id);
      return (
        <div className="d-flex align-items-center">
          <div
            className="name mr-2"
            style={{
              textTransform: 'uppercase',
              padding: '5px 10px',
              background: '#321640',
              color: '#fff',
              borderRadius: '6px',
            }}
          >
            {name.replace('_', ' ')}
          </div>
          <div className="data mr-2">
            {trim.trim_name || trim.name || trim.id || ''}
          </div>
        </div>
      );
    }
  };

  const dataInspect = () => {
    return (
      <div className="mb-2">
        <div className="title mb-2">
          <div
            className="d-inline-block"
            style={{
              padding: '17px 20px',
              background: '#321640',
              borderRadius: '50%',
            }}
          >
            <i
              className="fas fa-calendar"
              style={{ color: '#fff', fontSize: '18px' }}
            ></i>
          </div>
        </div>
        {Object.keys(inspectData).map((key, index) => {
          if (key !== 'detected_damages') {
            return (
              <div key={'dataInspect_' + index}>
                {key === 'ext_id' || key === 'int_id' || key === 'trim_id' ? (
                  searchDataVehicle(key, inspectData[key])
                ) : (
                  <div className="d-flex mt-2 align-items-center">
                    <div
                      className="name mr-2"
                      style={{
                        textTransform: 'uppercase',
                        padding: '5px 10px',
                        background: '#321640',
                        color: '#fff',
                        borderRadius: '6px',
                      }}
                    >
                      {key.replace('_', ' ')}
                    </div>
                    <div className="data">{inspectData[key]}</div>
                  </div>
                )}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  };
  return (
    <div
      className="modal image-support show-modal"
      style={{ overflow: 'auto' }}
    >
      <div
        className="overlay"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: '98',
        }}
        onClick={hideModal}
      ></div>
      <div className={`modal-dialog modal-full`} role="document">
        <div className="modal-content" style={{ zIndex: '99' }}>
          <div className="modal-header">
            <h5
              className="modal-title"
              dangerouslySetInnerHTML={{
                __html: 'Same Vehicle Data',
              }}
            />

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={hideModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={modalBodyStyle}>
            <div className="row">
              <div className="col-8">
                <div className="img-annotated" id="img-annotated">
                  <img
                    src={src}
                    width="100%"
                    height="auto"
                    alt="Agent"
                    style={{
                      borderRadius: '15px',
                      width: '100%',
                    }}
                    className="img-lazy-load"
                  ></img>
                  {damagePoint &&
                    damagePoint.length > 0 &&
                    damagePoint.map((item, index) => {
                      if (item.x && item.y) {
                        const style = {
                          top: item.y,
                          left: item.x,
                        };
                        return (
                          <div
                            key={`Damage_${index}_agent`}
                            id={`rnd-${index}-agent`}
                            className="damage-point"
                            style={style}
                          >
                            <div className="admin-point">{index + 1}</div>
                            {item.source ? (
                              <i
                                className="far fa-square"
                                style={{
                                  color: item.strokeColor,
                                }}
                              ></i>
                            ) : item.admin_response === 'reject' ||
                              item.user_response === 'reject' ? (
                              <i
                                className="fas fa-exclamation-triangle"
                                style={{
                                  color: item.strokeColor,
                                }}
                              ></i>
                            ) : item.admin_response === 'add' ? (
                              <i
                                className="far fa-circle"
                                style={{
                                  color: item.strokeColor,
                                }}
                              ></i>
                            ) : (
                              <i
                                className="far fa-dot-circle"
                                style={{
                                  color: item.strokeColor,
                                }}
                              ></i>
                            )}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
              </div>
              <div className="col-4">
                {inspectData && dataInspect()}
                {damageNotGen && damageNotGen.length > 0 && (
                  <DamageView damages={damageNotGen} view={true}></DamageView>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalCheckSameInspection;
