import React from 'react';

const CountDown = (props) => {
  return (
    <div className="count-down" id="count">
      <div className="timer">{props.countTimer}</div>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {!props.plusTime && props.inspectName === 'damage_body' ? (
        <div
          onClick={props.handlePlusTime}
          style={{
            position: 'absolute',
            right: '-20px',
            top: '0px',
            cursor: 'pointer',
          }}
          className="badge badge-warning"
        >
          <i className="fas fa-plus"></i>
        </div>
      ) : null}
    </div>
  );
};

export default CountDown;
