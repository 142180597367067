import React, { useState } from 'react';
import ModalShowErr from './ModalShowErr';
import SVG from 'react-inlinesvg';
//import * as THREE from 'three';
const Animation = (props) => {
  const [showListUrl, setshowListUrl] = useState(false);
  const noti =
    props.inspection &&
    props.inspection.sumaryTask &&
    props.inspection.sumaryTask.noti &&
    props.inspection.sumaryTask.noti.notification
      ? props.inspection.sumaryTask.noti.notification
      : 'If We have Task to do , I will get it for u!';
  let listUrlAni =
    props.inspection && props.inspection.listUrlAni
      ? props.inspection.listUrlAni
      : [];
  const doingTask = props.doingTask ? props.doingTask : null;
  const imgAniSelect =
    listUrlAni.length > 0 ? listUrlAni.find((item) => item.select).url : null;
  const handleOnChage = (url) => {
    const list = listUrlAni.map((item) => {
      item.select = false;
      if (item.url === url) {
        item.select = true;
      }
      return item;
    });
    props.handleChangeUrlAnition(list);
  };
  return (
    <div
      id="world"
      style={{
        position: 'absolute',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: '#321640',
        zIndex: props.doingTask ? '0' : '9999',
        transition: '0.5s',
        opacity: props.doingTask ? '0' : '1',
      }}
    >
      {!doingTask ? (
        <div className="img" style={{ width: '39%' }}>
          <SVG className={'svg'} src={imgAniSelect} uniquifyIDs={false}></SVG>
        </div>
      ) : null}

      <div
        className="back"
        style={{
          position: 'absolute',
          left: '30px',
          top: '25px',
          color: '#fff',
          fontSize: '38px',
          zIndex: '999',
          cursor: 'pointer',
        }}
        onClick={() => props.statusStart()}
      >
        <i className="fas fa-backspace"></i>
      </div>
      <div
        className="btn-list"
        style={{
          position: 'absolute',
          left: '30px',
          top: '79px',
          color: '#fff',
          fontSize: '38px',
          zIndex: '999',
          cursor: 'pointer',
        }}
      >
        {showListUrl ? (
          <div>
            <i
              className="fas fa-window-close"
              onClick={() => setshowListUrl(!showListUrl)}
            ></i>
            <div className="list-url-ani" style={{ display: 'flex' }}>
              {listUrlAni.length > 0
                ? listUrlAni.map((item, index) => {
                    return (
                      <div
                        key={`url-ani-${index}`}
                        className="item mr-2"
                        onClick={() => handleOnChage(item.url)}
                        style={{
                          position: 'relative',
                          border: `3px solid ${
                            item.select ? '#85D38E' : '#fff'
                          }`,
                          borderRadius: '10px',
                          width: '150px',
                          height: '150px',
                          padding: '8px',
                        }}
                      >
                        <SVG
                          className={'svg'}
                          src={item.url}
                          uniquifyIDs={false}
                        ></SVG>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        ) : (
          <i
            className="fas fa-list-ul"
            onClick={() => setshowListUrl(!showListUrl)}
          ></i>
        )}
      </div>
      <div
        className="box-text"
        style={{
          position: 'absolute',
          width: '100%',
          top: '50%',
          color: '#fff',
          display: 'flex',
          fontFamily: 'Lobster',
          fontSize: '36px',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '50%',
            borderRadius: '10px',
            background: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            position: 'relative',
            padding: '5px 20px',
            opacity: '0.7',
          }}
        >
          <span style={{ color: '#000' }}>{noti}</span>
          <div
            className="author"
            style={{
              position: 'absolute',
              right: 0,
              bottom: '-50%',
              color: '#fff',
              zIndex: '999',
              fontSize: '19px',
            }}
          >
            <span className="mr-2" style={{ opacity: '0.6' }}>
              by
            </span>
            <span>Tuan.Admin</span>
          </div>
        </div>
      </div>
      {props.showmodalPriority && (
        <ModalShowErr
          messErr={'Too many priorities , if u want to inspect plz solve it'}
          resetError={() => props.statusStart()}
        />
      )}
      {props.showmodalErr ? (
        <ModalShowErr
          messErr={props.messErr}
          resetError={() => props.logOut()}
        />
      ) : null}
    </div>
  );
};

export default Animation;
