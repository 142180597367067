import { default as Label } from './Label';
import { default as Draw } from './Draw';
import { default as Instruction } from './Instruction';
const Guideline = {
	Draw,
	Label,
	Instruction,
};

export default Guideline;
