import React from 'react';

const AcceptButton = (props) => {
	let { taskSettings } = props;
	return taskSettings && taskSettings.accepts && taskSettings.accepts.length
		? taskSettings.accepts.map((button, index) => {
				return (
					<div className="show-modal-button" key={index}>
						<button
							key={index}
							type="button"
							name={`${button.name}`}
							className="btn btn-success mb-2"
							onClick={() =>
								props.showModalBtn(
									'Accept',
									button.name,
									button.description
								)
							}
						>
							<i
								className="fa fa-check mr-2 check-icon"
								aria-hidden="true"
							/>
							{button.description}
						</button>
					</div>
				);
		  })
		: null;
};

export default AcceptButton;
