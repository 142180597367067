import React from 'react';
import PropTypes from 'prop-types';
import { AccordionItem, NoteItem } from '../widgets';

const CageViewNotes = ({ data, show, settings, parent }) => {
	const total = data.length;
	let totalIndex = total + 1;
	const bodyContent = (
		<div className="card-columns">
			{data.map(item => {
				totalIndex--;
				return (
					<NoteItem
						key={item._id}
						index={totalIndex}
						data={item}
						settings={settings}
					/>
				);
			})}
		</div>
	);
	return (
		<AccordionItem
			id="notes"
			buttonLabel={`Notes (${total})`}
			bodyContent={bodyContent}
			show={show}
			parent={parent}
		/>
	);
};

CageViewNotes.propTypes = {
	data: PropTypes.array.isRequired
};

CageViewNotes.defaultProps = {
	show: false
};

export default CageViewNotes;
