export const GET_CAGES = 'app/cages/GET_CAGES';
export const GET_CAGE = 'app/cages/GET_CAGE';
export const GET_CAGE_DETAIL = 'app/cages/GET_CAGE_DETAIL';
export const POST_CAGE_NOTE = 'app/cages/POST_CAGE_NOTE';
export const PUT_CAGE = 'app/cages/PUT_CAGE';
export const PUT_CAGE_DETAIL = 'app/cages/PUT_CAGE_DETAIL';
export const PUT_CAGE_STATUS = 'app/cages/PUT_CAGE_STATUS';
export const PUT_CAGE_LABEL = 'app/cages/PUT_CAGE_LABEL';
export const FILE_UPLOAD = 'app/cages/FILE_UPLOAD';
export const IS_LOADING = 'app/cages/IS_LOADING';
export const HAS_ERROR = 'app/cages/HAS_ERROR';
