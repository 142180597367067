import { default as Login } from './Login';
import { default as ControlBar } from './ControlBar';
import { default as Account } from './Account';
const Auth = {
	ControlBar,
	Login,
	Account
};

export default Auth;
