import React from 'react';
import Nav from 'react-bootstrap/Nav';
const ListTab = (props) => {
	const { bodyData } = props;
	return (
		<Nav variant="pills" className="flex-column">
			{bodyData.map((item, index) => {
				return (
					<Nav.Item key={`item-` + index}>
						<Nav.Link eventKey={`${index}`}>
							<span>{item.bodytype}</span>
						</Nav.Link>
					</Nav.Item>
				);
			})}
		</Nav>
	);
};

export default ListTab;
