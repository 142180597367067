import React from 'react';
import PropTypes from 'prop-types';

const AccordionItem = ({
	id,
	actions,
	buttonLabel,
	bodyContent,
	show,
	length,
	parent
}) => {
	return (
		<div className="card">
			<div className="card-header" id={`Accordion_${id}_head`}>
				<h2 className="mb-0">
					<button
						className={`btn btn-link ${show ? '' : 'collapsed'}`}
						type="button"
						data-toggle="collapse"
						data-target={`#Accordion_${id}_content`}
						aria-expanded="true"
						aria-controls={`Accordion_${id}_content`}
					>
						{buttonLabel}
					</button>
					<span>{length}</span>
					{actions}
				</h2>
			</div>
			<div
				id={`Accordion_${id}_content`}
				className={`collapse ${show ? 'show' : ''}`}
				aria-labelledby={`Accordion_${id}_head`}
				data-parent={parent}
			>
				<div className="card-body">{bodyContent}</div>
			</div>
		</div>
	);
};

AccordionItem.propTypes = {
	buttonLabel: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	parent: PropTypes.string
};

AccordionItem.defaultProps = {
	show: false,
	parent: ''
};

export default AccordionItem;
