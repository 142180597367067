import React, { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { Auth } from './app/auth';
import { UI } from './app/ui';
import jwt_decode from 'jwt-decode';
import PrivateRoute from './components/common/PrivateRoute';

import ErrorBoundary from './errorBoundary';
import { Cage } from './app/cages';
import { Guideline } from './app/instruction';
import { Inspection } from './app/inspections';
import authActions from './app/auth/actions';
import { setAuthToken, logout } from './app/auth/operations';
import { TaskFailed } from './app/taskFailed';
import Spinner from './components/common/Spinner';
import './App.scss';

const history = createBrowserHistory();
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(authActions.setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logout());
    // Redirect to login
    window.location.href = '/';
  }
}

const App = () => {
  // State to store the initial title

  const handleBeforeUnload = async (event) => {
    event.preventDefault();
    event.returnValue = 'Are you sure you want to leave?';
    if (localStorage.jwtToken) {
      // Set auth token header auth
      setAuthToken(localStorage.jwtToken);
      // Decode token and get user info and exp
      const decoded = jwt_decode(localStorage.jwtToken);
      // Set user and isAuthenticated
      if (decoded) {
        await store.dispatch(logout());
      }
    }

    //
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={<Spinner />} persistor={persistor}>
          <Router history={history}>
            <div className="App">
              <UI.Alert />
              <UI.Loading></UI.Loading>
              <Switch>
                <Route exact path="/" component={Auth.Login} />
              </Switch>
              <PrivateRoute component={Auth.ControlBar} history={history} />
              <section id="Workspace">
                <div id="Routes" className="routes">
                  <Route exact path="/account" component={Auth.Account} />
                  <Route exact path="/cages" component={Cage.List} />
                  <Route exact path="/cageView/:id" component={Cage.View} />
                  <Route exact path="/cageLabel/:id" component={Cage.Label} />
                  <Route
                    exact
                    path="/inspections"
                    component={Inspection.AgentInspect}
                  />
                  <Route
                    exact
                    path="/performance/statistic"
                    component={TaskFailed.Statistic}
                  />
                  <Route
                    exact
                    path="/training"
                    component={Guideline.Instruction}
                  />
                  <Route
                    exact
                    path="/training/label"
                    component={Guideline.Label}
                  />
                </div>
              </section>
            </div>
          </Router>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
