import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const BoxHotKeys = () => {
  const arrHotkeys = [
    {
      hotkey: 'F',
      des: 'Change Image Webp/Super',
    },
    {
      hotkey: 'W',
      des: 'Zoom Image',
    },
    {
      hotkey: 'R',
      des: 'Show Cage',
    },
    {
      hotkey: '⌴',
      des: 'Rotate Image',
    },
    {
      hotkey: '(n)',
      des: 'Show Support Image',
    },
    {
      hotkey: 'Q',
      des: 'On/Off Cage',
    },
    {
      hotkey: 'T',
      des: 'Change Cage',
    },
    {
      hotkey: 'S',
      des: 'Show Full Support Image',
    },
    {
      hotkey: 'X',
      des: 'Delete Damages',
    },
    {
      hotkey: 'C',
      des: 'Show Same Inspect',
    },
  ];
  return (
    <div className="box-hotkey">
      <div className="title">Hot Keys</div>
      <span>Hover these button to know how hotkey work</span>
      <div className="btn-hotkeys mt-2">
        {arrHotkeys.map((item, index) => {
          const length = arrHotkeys.length ? arrHotkeys.length : 1;
          const width = 100 / length;
          return (
            <OverlayTrigger
              placement="top"
              key={`hotkey_` + index}
              overlay={<Tooltip id="button-tooltip-2">{item.des}</Tooltip>}
            >
              <div className="hotkey" style={{ width: `${width}%` }}>
                <div className="button">{item.hotkey}</div>
              </div>
            </OverlayTrigger>
          );
        })}
      </div>
    </div>
  );
};

export default BoxHotKeys;
