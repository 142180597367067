import React, { Component } from 'react';
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class TabContent extends Component {
	render() {
		let { bodyData, position } = this.props;

		return (
			<Tab.Content>
				{bodyData.map((item, index) => {
					const imageBody = item.imageBody ? item.imageBody : {};
					let checkPosition = {};
					let activeFront = '';
					let activeRear = '';
					let activeLeft = '';
					let activeImage = '';
					if (position === 'Left') {
						checkPosition = imageBody.left ? imageBody.left : {};
						activeLeft = 'active';
						activeImage = 'left';
					} else if (position === 'Front') {
						checkPosition = imageBody.front ? imageBody.front : {};
						activeFront = 'active';
						activeImage = 'front';
					} else if (position === 'Rear') {
						checkPosition = imageBody.rear ? imageBody.rear : {};
						activeRear = 'active';
						activeImage = 'rear';
					}
					return (
						<Tab.Pane
							eventKey={`${index}`}
							key={`content-` + index}
						>
							<Row className="justify-content-md-center">
								<Col md={12}>
									<div className="car">
										<div className="group">
											<button
												className={
													`btn btn-car ` +
													`${activeFront}`
												}
												onClick={() =>
													this.props.changePosition(
														'Front'
													)
												}
											>
												<span>Front</span>
											</button>
											<button
												className={
													`btn btn-car ` +
													`${activeRear}`
												}
												onClick={() =>
													this.props.changePosition(
														'Rear'
													)
												}
											>
												<span>Rear</span>
											</button>
											<button
												className={
													`btn btn-car ` +
													`${activeLeft}`
												}
												onClick={() =>
													this.props.changePosition(
														'Left'
													)
												}
											>
												<span>Left/Right</span>
											</button>
										</div>
										<img
											src={`${checkPosition.imageSVG}`}
											className={
												`image-svg ` + activeImage
											}
											alt="img"
										/>
									</div>
								</Col>
							</Row>
						</Tab.Pane>
					);
				})}
			</Tab.Content>
		);
	}
}

export default TabContent;
