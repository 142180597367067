import actions from './actions';
import * as client from '../../utils/client';
import * as typesAuth from '../auth/types';
let agentAPI =
  process.env.REACT_APP_AGENT_BASE_URL || 'https://agent.paveapi.com';

export const getAllTaskFailed = (params) => {
  return (dispatch) =>
    client.exec(
      'get',
      `${agentAPI}/api/taskFailed/getAllTaskFailed`,
      params,
      function (res) {
        dispatch(actions.getAllTaskFailed(res));
        dispatch({
          type: typesAuth.UPDATE_TASKFAILED,
          payload:
            res && res.totalTaskFailed
              ? { totalTaskFailed: res.totalTaskFailed }
              : { totalTaskFailed: 0 },
        });
      },
      true,
    );
};
export const getStatistic = (params) => {
  return (dispatch) =>
    client.exec(
      'get',
      `${agentAPI}/api/taskFailed/statisticTaskFailed`,
      params,
      function (res) {
        dispatch(actions.getStatistic(res));
      },
      true,
    );
};
export const getTaskFailed = (id) => {
  return (dispatch) =>
    client.exec(
      'get',
      `${agentAPI}/api/taskFailed/getTaskFailed/${id}`,
      {},
      function (res) {
        dispatch(actions.getTaskFailed(res));
      },
      true,
    );
};
export const confirmTaskFailed = (data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/taskFailed/confirmTaskFailed`,
      data,
      function (res) {
        dispatch(actions.confirmTaskFailed(res));
        dispatch({
          type: typesAuth.UPDATE_TASKFAILED,
          payload:
            res && res.totalTaskFailed
              ? { totalTaskFailed: res.totalTaskFailed }
              : { totalTaskFailed: 0 },
        });
      },
      true,
    );
};
export const deleteTaskFailed = (data) => {
  return (dispatch) =>
    client.exec(
      'delete',
      `${agentAPI}/api/taskFailed/deleteTaskFailed`,
      data,
      function (res) {
        dispatch({
          type: typesAuth.UPDATE_TASKFAILED,
          payload:
            res && res.totalTaskFailed
              ? { totalTaskFailed: res.totalTaskFailed }
              : { totalTaskFailed: 0 },
        });
      },
      true,
    );
};
export const findFromList = (list, key, value) => {
  if (list && list.length) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item[key] && item[key].id === parseInt(value, 10)) {
        return item[key];
      }
    }
  }
  return false;
};
