import React from 'react';

const RejectButton = (props) => {
  const {
    taskSettings,
    inspectionInspect,
    theme,
    inspectName,
    taskSlot,
    taskInspect,
    photoCode,
  } = props;
  const inspectData = taskInspect.inspectData ? taskInspect.inspectData : null;
  const themeName = theme && theme.name ? theme.name : null;
  const qcPassed = inspectionInspect.qcPassed
    ? inspectionInspect.qcPassed
    : false;
  const qc_only_accept =
    inspectionInspect &&
    inspectionInspect.options &&
    inspectionInspect.options.qc_only_accept
      ? inspectionInspect.options.qc_only_accept
      : null;
  const qc_on_having_odometer =
    inspectionInspect &&
    inspectionInspect.options &&
    inspectionInspect.options.qc_on_having_odometer
      ? inspectionInspect.options.qc_on_having_odometer
      : false;
  const skip_vehicle_data_check =
    inspectionInspect &&
    inspectionInspect.options &&
    inspectionInspect.options.skip_vehicle_data_check
      ? inspectionInspect.options.skip_vehicle_data_check
      : null;
  const skip_vehicle_record_check =
    inspectionInspect &&
    inspectionInspect.options &&
    inspectionInspect.options.skip_vehicle_record_check
      ? inspectionInspect.options.skip_vehicle_record_check
      : null;
  const reject_vin_not_from_vehicle =
    inspectionInspect &&
    inspectionInspect.options &&
    inspectionInspect.options.reject_vin_not_from_vehicle
      ? inspectionInspect.options.reject_vin_not_from_vehicle
      : null;
  const checkSkipQC =
    inspectionInspect &&
    inspectionInspect.options &&
    inspectionInspect.options.customized &&
    inspectionInspect.options.customized.custom_skip_qc &&
    inspectionInspect.options.customized.custom_skip_qc.length > 0
      ? inspectionInspect.options.customized.custom_skip_qc.map((item) => {
          if (item < 10) {
            return `0${item}`;
          } else {
            return `${item}`;
          }
        })
      : [];
  return (
    <div className="groupReject mt-2" style={{ textAlign: 'center' }}>
      {taskSettings && taskSettings.rejects && taskSettings.rejects.length
        ? taskSettings.rejects.map((button, index) => {
            if (
              qcPassed &&
              ['DEFAULT', 'DEF-1', 'DEF-2', 'DEF-3'].includes(
                inspectionInspect.group,
              )
            ) {
              return null;
            }
            if (
              !qcPassed &&
              ['mismatched_record', 'mismatched_vehicle'].includes(
                button.name,
              ) &&
              ['AMZ', 'AMZ-1', 'AMZ-2', 'AMZ-3'].includes(
                inspectionInspect.group,
              )
            ) {
              return null;
            }
            if (
              qcPassed &&
              !['mismatched_record', 'mismatched_vehicle'].includes(button.name)
            ) {
              return null;
            }
            if (qc_only_accept) {
              return null;
            }
            if (checkSkipQC.includes(taskSlot.photoCode)) {
              return null;
            }
            if (
              skip_vehicle_record_check &&
              button.name === 'mismatched_record'
            ) {
              return null;
            }
            if (
              skip_vehicle_data_check &&
              button.name === 'mismatched_vehicle'
            ) {
              return null;
            }
            if (
              !reject_vin_not_from_vehicle &&
              button.name === 'vin_not_from_vehicle' &&
              themeName !== 'CAPT'
            ) {
              return null;
            }
            if (
              qc_on_having_odometer &&
              photoCode === '03' &&
              inspectData &&
              (button.name === 'no_odometer' ||
                button.name === 'mismatched_vehicle')
            ) {
              return null;
            }
            if (
              inspectName !== 'qc_plate' &&
              button.name === 'vrm_not_visible'
            ) {
              return null;
            } else {
              return (
                <button
                  type="button"
                  key={index + 1}
                  name={button.name}
                  style={{
                    display: 'inline-block',
                    marginTop: index === 5 ? '10px' : '0',
                    fontSize: '14px',
                  }}
                  className={`btn ${
                    button.name === 'vin_not_from_vehicle'
                      ? 'btn-warning'
                      : 'btn-danger'
                  } mb-2`}
                  onClick={() =>
                    props.showModalBtn(
                      'Reject',
                      button.name,
                      button.description,
                    )
                  }
                >
                  <i className="fa fa-times" aria-hidden="true" />
                  {button.name.replace(/_/g, ' ')}
                </button>
              );
            }
          })
        : null}
    </div>
  );
};

export default RejectButton;
