import React, { useRef, useState } from 'react';
import PrismaZoom from 'react-prismazoom';
import SVG from 'react-inlinesvg';
import reactCSS from 'reactcss';
import Hotkeys from 'react-hot-keys';
import $ from 'jquery';
import Select from 'react-select';
import { Rnd } from 'react-rnd';
import CountDown from './CountDown';
import Testing from '../../../../components/common/Testing';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
window.$ = $;

const PhotoFull = (props) => {
  let prismaZoom = useRef('prismaZoom');
  const [hoverIdDamage, sethoverIdDamage] = useState(null);
  const [showModalMaterial, setshowModalMaterial] = useState(false);
  const [chooseMaterial, setchooseMaterial] = useState(false);
  const [listMaterial, setlistMaterial] = useState([
    {
      name: 'Painted',
      enable: false,
      hotKey: 'p',
      icon: 'fas fa-fill-drip',
      url: 'url(../../img/material/painted.svg),auto',
    },
    {
      name: 'Textured Surface',
      enable: false,
      hotKey: 'o',
      icon: 'fas fa-recycle',
      url: 'url(../../img/material/surface.svg),auto',
    },
    {
      name: 'Chrome',
      enable: false,
      hotKey: 'i',
      icon: 'far fa-gem',
      url: 'url(../../img/material/chrome.svg),auto',
    },
    {
      name: 'Steel',
      enable: false,
      hotKey: 'u',
      icon: 'fas fa-dharmachakra',
      url: 'url(../../img/material/steel.svg),auto',
    },
    {
      name: 'Alloy Wheel',
      enable: false,
      hotKey: 'y',
      icon: 'fas fa-drum-steelpan',
      url: 'url(../../img/material/alloy.svg),auto',
    },
    {
      name: 'Plastic Cover',
      enable: false,
      hotKey: 'j',
      icon: 'fas fa-record-vinyl',
      url: 'url(../../img/material/plastic.svg),auto',
    },
    {
      name: 'Steel Wheel',
      enable: false,
      hotKey: 'k',
      icon: 'fas fa-dumbbell',
      url: 'url(../../img/material/steelWheel.svg),auto',
    },
    {
      name: 'Glass',
      enable: false,
      hotKey: 'l',
      icon: 'fas fa-glass-whiskey',
      url: 'url(../../img/material/glass.svg),auto',
    },
  ]);
  const checkLeftRight = () => {
    let layout = '';
    let { taskSlot, taskType } = props;
    if (
      taskSlot &&
      taskSlot.inspects &&
      taskType !== 'QC' &&
      taskSlot.photoCode === '07'
    ) {
      layout =
        taskSlot.inspects.position && taskSlot.inspects.position === 'rear'
          ? 'layout-rear'
          : 'layout-front';
    } else if (
      taskSlot &&
      taskSlot.inspects &&
      taskType !== 'QC' &&
      taskSlot.photoCode === '04'
    ) {
      layout =
        taskSlot.inspects.position && taskSlot.inspects.position === 'rear'
          ? 'layout-front'
          : 'layout-rear';
    } else {
      layout = '';
    }
    return <div className={`${layout}`}></div>;
  };
  const modalMaterial = () => {
    return (
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: '9999',
          borderRadius: '5px',
        }}
      >
        <div
          className="tool-mate"
          style={{
            display: 'flex',
            width: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#e9e9ed',
            borderRadius: '5px',
          }}
        >
          {listMaterial.map((item, index) => {
            return (
              <div
                key={`mate-${index}`}
                style={{
                  fontSize: '20px',
                  cursor: 'pointer',
                  width: '100%',
                  textAlign: 'center',
                  color: item.enable ? '#fff' : '',
                  background: item.enable ? '#321640' : '',
                  borderRadius: '5px',
                  padding: '5px 0',
                }}
                onClick={() => selectMaterial(item.name)}
              >
                <i className={`${item.icon}`}></i>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const onToggle = () => {
    selectMaterial('');
    setchooseMaterial(false);
  };
  const toolMaterial = () => {
    return (
      <div
        className="tool-mate"
        style={{
          float: 'right',
          display: 'flex',
          flexDirection: 'column',
          width: '3%',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '8px',
          background: '#e9e9ed',
          borderRadius: '5px',
        }}
      >
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="ChooseMaterial"
            checked={chooseMaterial}
            onChange={onToggle}
          />
          <label
            className="custom-control-label"
            htmlFor="ChooseMaterial"
          ></label>
        </div>
        {listMaterial.map((item, index) => {
          return (
            <OverlayTrigger
              key={item.name}
              placement="right"
              overlay={
                <Tooltip id={`tooltip-${item.name}`}>
                  <strong>
                    {item.name} ({item.hotKey})
                  </strong>
                </Tooltip>
              }
            >
              <div
                key={`mate-${index}`}
                style={{
                  marginBottom: '8px',
                  fontSize: '22px',
                  cursor: 'pointer',
                  width: '100%',
                  textAlign: 'center',
                  color: item.enable ? '#fff' : '',
                  background: item.enable ? '#321640' : '',
                  borderRadius: '5px',
                  padding: '5px 0',
                }}
                onClick={() => selectMaterial(item.name, true)}
              >
                <i className={`${item.icon}`}></i>
              </div>
            </OverlayTrigger>
          );
        })}
      </div>
    );
  };
  // Damage
  const updateDamage = (e, id) => {
    let { damages } = props;
    if (damages) {
      damages[id] = { ...damages[id], ...e };
      props.clearlistDamages();
      props.updateDamage(damages);
    }
  };
  const resetZoom = (e) => {
    let { taskInspect } = props;
    let zoom = 1;
    if (taskInspect.photoCode !== '03' && taskInspect.photoCode !== '01') {
      zoom = prismaZoom.current.getZoom();
    }
    if (zoom > 1) {
      prismaZoom.current.reset();
    }
  };
  const deleteDamage = () => {
    const { damages } = props;
    if (damages[hoverIdDamage]) {
      damages.splice(hoverIdDamage, 1);
      props.deleteDamage(damages);
    }
  };
  const selectMaterial = (item, select) => {
    let list = listMaterial;
    for (let i = 0; i < list.length; i++) {
      const material = list[i];
      if (material.name === item) {
        list[i].enable = true;
      } else {
        list[i].enable = false;
      }
    }
    if (select) {
      setchooseMaterial(true);
    }
    setlistMaterial([...list]);
  };
  const toolHotKey = () => {
    let {
      imgSupport,
      onModalImage,
      offModalImage,
      inspectionInspect,
      taskSlot,
    } = props;
    const tableDamage =
      inspectionInspect &&
      inspectionInspect.options &&
      inspectionInspect.options.damage_table
        ? inspectionInspect.options.damage_table
        : null;
    let photoSearch01, photoSearch02;
    switch (taskSlot.photoCode) {
      case '04':
        photoSearch01 = 'capture/10';
        photoSearch02 = 'capture/13';
        break;
      case '05':
        photoSearch01 = 'capture/10';
        photoSearch02 = 'capture/11';

        break;
      case '07':
        photoSearch01 = 'capture/11';
        photoSearch02 = 'capture/12';
        break;
      case '08':
        photoSearch01 = 'capture/12';
        photoSearch02 = 'capture/13';
        break;
      default:
        break;
    }
    if (
      ['04', '05', '07', '08'].includes(taskSlot.photoCode) &&
      imgSupport.length > 0
    ) {
      imgSupport = [
        ...imgSupport.filter((item) => item.includes(photoSearch01)),
        ...imgSupport.filter((item) => item.includes(photoSearch02)),
        ...imgSupport.filter(
          (item) =>
            !item.includes(photoSearch01) && !item.includes(photoSearch02),
        ),
      ];
    }
    return (
      <div className="tool-cage" style={{ display: 'none' }}>
        {listMaterial &&
        listMaterial.length > 0 &&
        tableDamage !== 'bmw' &&
        tableDamage !== 'autograde'
          ? listMaterial.map((item) => {
              return (
                <Hotkeys
                  key={`hotmaterial_` + item.hotKey}
                  keyName={`${item.hotKey}`}
                  onKeyDown={() => selectMaterial(item.name, true)}
                />
              );
            })
          : null}
        {tableDamage !== 'bmw' && tableDamage !== 'autograde' ? (
          <Hotkeys
            keyName="tab"
            onKeyDown={() => setshowModalMaterial(true)}
            onKeyUp={() => setshowModalMaterial(false)}
          ></Hotkeys>
        ) : null}
        <Hotkeys keyName="space" onKeyDown={() => props.setRotate()}>
          <div onClick={() => props.setRotate()}></div>
        </Hotkeys>
        <Hotkeys keyName="x" onKeyDown={() => deleteDamage()}></Hotkeys>
        <Hotkeys keyName="r" onKeyDown={() => props.calShowCage()}>
          <div onClick={() => props.setRotate()}></div>
        </Hotkeys>
        <button
          type="button"
          className="btn btn-reset"
          onClick={() => resetZoom()}
        ></button>
        <Hotkeys
          keyName="s"
          onKeyDown={() => props.onModalImageFull()}
          onKeyUp={() => props.offModalImageFull()}
        ></Hotkeys>
        {inspectionInspect.sameInspection && (
          <Hotkeys
            keyName="c"
            onKeyDown={props.onModalCheckSameInspection}
            onKeyUp={props.offModalCheckSameInspection}
          ></Hotkeys>
        )}

        {imgSupport && imgSupport.length > 0
          ? imgSupport.map((item, index) => {
              return (
                <Hotkeys
                  key={`Image_` + index}
                  keyName={`${index + 1}`}
                  onKeyDown={() => onModalImage(item, index + 1)}
                  onKeyUp={() => offModalImage()}
                ></Hotkeys>
              );
            })
          : null}

        <Hotkeys keyName="t" onKeyDown={() => props.onReload()}>
          <div
            onClick={() => {
              props.onReload();
            }}
          ></div>
        </Hotkeys>
        <Hotkeys
          keyName="w"
          onKeyDown={() => props.setScaleDown()}
          onKeyUp={() => props.setScaleUp()}
        ></Hotkeys>
        <Hotkeys keyName="q" onKeyUp={(e) => props.OnOfSvg(e)}></Hotkeys>
      </div>
    );
  };
  const addDamage = (e) => {
    let {
      damages,
      idDamage,
      getDamages,
      vehicle,
      inspectionInspect,
      taskType,
      taskSlot,
      inspectName,
    } = props;
    const zoom = taskType !== 'QC' ? prismaZoom.current.getZoom() : 1;
    const model = vehicle && vehicle.model ? vehicle.model : false;
    const make = vehicle && vehicle.make ? vehicle.make : false;
    if (zoom === 1 && taskType !== 'QC') {
      prismaZoom.current.reset();
    }
    const groupInspect =
      taskSlot.inspects && taskSlot.inspects.type
        ? taskSlot.inspects.type
        : null;
    if (zoom > 1) {
      prismaZoom.current.zoomToZone(null);
      prismaZoom.current.reset();
    }
    if (damages && damages.length > 0) {
      for (let i = 0; i < damages.length; i++) {
        const dama = damages[i];
        if (
          dama.damage_location &&
          dama.damage_location.x === idDamage.x &&
          dama.damage_location.y === idDamage.y &&
          !dama.damage_name
        ) {
          const pointRnd = document.getElementById(`damage-item-${i}`);
          const point = document.getElementById(`rnd-${i}`);
          if (pointRnd && point) {
            pointRnd.classList.add('heartBeat');
            point.classList.add('heartBeat');
            setTimeout(() => {
              pointRnd.classList.remove('heartBeat');
              point.classList.remove('heartBeat');
            }, 1000);
          }
          return false;
        }
      }
    }
    let offset = $('.App-zoom').offset();
    let offsetWidth = $('.App-zoom').width();
    let offsetHeight = $('.App-zoom').height();
    let relX = (e.pageX - offset.left) / zoom;
    let relY = (e.pageY - offset.top) / zoom;
    let partId = e.target.getAttribute('data-name');
    let partGroup = e.target.getAttribute('group');
    let partMaterial = e.target.getAttribute('material');
    if (!partId || !partGroup) {
      return;
    }
    if (inspectName === 'damage_other' && partGroup === 'BODY') {
      return;
    }
    if (
      inspectName === 'damage_body' &&
      ['PART', 'WHEEL', 'GLASS'].includes(partGroup)
    ) {
      return;
    }
    const tableDamage =
      inspectionInspect &&
      inspectionInspect.options &&
      inspectionInspect.options.damage_table
        ? inspectionInspect.options.damage_table
        : null;
    const damageSource =
      inspectionInspect &&
      inspectionInspect.options &&
      inspectionInspect.options.damages_source
        ? inspectionInspect.options.damages_source
        : false;
    if (tableDamage === 'autograde') {
      const item = {
        component: partId,
        damage_group: partGroup,
      };
      getDamages(item, tableDamage);
    } else if (tableDamage === 'bmw') {
      const item = {
        component: partId,
        damage_group: partGroup,
        series_nme: make === 'MINI' ? 'MINI' : model,
      };
      getDamages(item, tableDamage);
    } else {
      const mate = listMaterial.find((item) => item.enable);
      let item = { component: partId, damage_group: partGroup };
      if (chooseMaterial) {
        item = {
          ...item,
          material: mate && mate.name ? mate.name : false,
        };
      } else {
        if (partMaterial) {
          item = {
            ...item,
            material: partMaterial,
          };
          selectMaterial(partMaterial);
        } else {
          switch (partGroup) {
            case 'BODY':
              selectMaterial('Painted');
              break;
            case 'PART':
              selectMaterial('Textured Surface');
              break;
            case 'GLASS':
              selectMaterial('Glass');
              break;
            default:
              break;
          }
          const mate = listMaterial.find((item) => item.enable);
          item = {
            ...item,
            material: mate && mate.name ? mate.name : null,
          };
        }
      }
      if (damageSource) {
        item = {
          ...item,
          source: damageSource,
        };
      }
      getDamages(item);
    }

    if (partId && partGroup !== 'TIRE' && groupInspect === 'damage') {
      damages.push({
        component: partId,
        damage_group: partGroup,
        damage_location: {
          x: parseFloat(Number(relX).toFixed(4)),
          y: parseFloat(Number(relY).toFixed(4)),
        },
        actual_image: {
          width: parseFloat(Number(offsetWidth).toFixed(4)),
          height: parseFloat(Number(offsetHeight).toFixed(4)),
        },
      });
      props.setidDamage(
        parseFloat(Number(relX).toFixed(4)),
        parseFloat(Number(relY).toFixed(4)),
      );
      props.addDamages(damages);
    }
  };
  const showImageFull = () => {
    let {
      taskInspect,
      damages,
      svg,
      cagePo,
      rotate,
      scale,
      countTimer,
      disableDragging,
      checkTest,
      listDamages,
      vehicle,
      img,
      inspectionInspect,
      plusTime,
      handlePlusTime,
      inspectName,
    } = props;
    const mate = listMaterial.find((item) => item.enable);
    const tableDamage =
      inspectionInspect &&
      inspectionInspect.options &&
      inspectionInspect.options.damage_table
        ? inspectionInspect.options.damage_table
        : null;
    const styles = reactCSS({
      default: {
        img: {
          transform: `rotate(${rotate}deg)`,
        },
      },
    });
    const formatOptionLabel = ({
      label,
      unit_measure,
      grade_score,
      material,
    }) => (
      <div style={{ display: 'flex' }}>
        {material ? (
          <div
            style={{ color: '#fff' }}
            className="badge badge-primary text-head text-uppercase"
          >
            {material === 'Painted' ? (
              <i className="fas fa-fill-drip"></i>
            ) : material === 'Textured Surface' ? (
              <i className="fas fa-recycle"></i>
            ) : material === 'Chrome' ? (
              <i className="far fa-gem"></i>
            ) : material === 'Alloy Wheel' ? (
              <i className="fas fa-drum-steelpan"></i>
            ) : material === 'Plastic Cover' ? (
              <i className="fas fa-record-vinyl"></i>
            ) : material === 'Steel Wheel' ? (
              <i className="fas fa-dumbbell"></i>
            ) : material === 'Glass' ? (
              <i className="fas fa-glass-whiskey"></i>
            ) : material === 'Steel' ? (
              <i className="fas fa-dharmachakra"></i>
            ) : null}
          </div>
        ) : null}
        <div
          style={{ color: '#fff' }}
          className="badge badge-primary text-head text-uppercase ml-1"
        >
          {unit_measure &&
          (unit_measure.includes('Component') ||
            unit_measure.includes('component'))
            ? 'C'
            : 'I'}
        </div>
        <div
          className="badge badge-primary text-head text-uppercase ml-1"
          style={{
            color: grade_score === 0 ? '#fff' : '#000',
            background:
              grade_score === 0
                ? 'grey'
                : grade_score === 1
                ? 'yellow'
                : grade_score === 2
                ? 'orange'
                : grade_score >= 3
                ? 'red'
                : '',
          }}
        >
          {label}
        </div>
        <div
          style={{
            color: grade_score === 0 ? '#fff' : '#000',
            background:
              grade_score === 0
                ? 'grey'
                : grade_score === 1
                ? 'yellow'
                : grade_score === 2
                ? 'orange'
                : grade_score >= 3
                ? 'red'
                : '',
          }}
          className="badge badge-primary text-head text-uppercase ml-1"
        >
          {grade_score}
        </div>
      </div>
    );
    let damagePoint = [];
    let typeSelectList = [];
    const damagesAPI =
      listDamages &&
      listDamages.success &&
      listDamages.damages &&
      listDamages.damages.length
        ? listDamages.damages
        : false;
    let waiting = true;
    if (listDamages && listDamages.success) {
      waiting = false;
    }
    if (damagesAPI) {
      for (let h = 0; h < damagesAPI.length; h++) {
        const damage = damagesAPI[h];
        typeSelectList.push({
          ...damage,
          damage_type: damage.damage_type
            ? damage.damage_type
            : damage.damage_name
            ? damage.damage_name
            : '',
          des: damage.label,
          label: damage.damage_name,
        });
      }
    }
    for (let i = 0; i < damages.length; i++) {
      let damage = damages[i];
      const style = {
        top:
          damage.damage_location && damage.damage_location.y
            ? damage.damage_location.y
            : null,
        left:
          damage.damage_location && damage.damage_location.x
            ? damage.damage_location.x
            : null,
        color:
          damage.grade_score === 0
            ? 'grey'
            : damage.grade_score === 1
            ? 'yellow'
            : damage.grade_score === 2
            ? 'orange'
            : damage.grade_score >= 3
            ? 'red'
            : '',
        cursor: 'pointer',
      };
      damagePoint.push(
        <div
          key={`Damage_${i}`}
          id={`rnd-${i}`}
          className="damage-point"
          style={style}
          onMouseEnter={() => sethoverIdDamage(i)}
          onMouseLeave={() => sethoverIdDamage(null)}
        >
          {damage.damage_location &&
          damage.damage_location.x > damage.actual_image.width / 2 ? (
            <div className="admin-point-right">{i + 1}</div>
          ) : (
            <div className="admin-point">{i + 1}</div>
          )}
          <i
            style={{
              color:
                damage.grade_score === 0
                  ? 'grey'
                  : damage.grade_score === 1
                  ? 'yellow'
                  : damage.grade_score === 2
                  ? 'orange'
                  : damage.grade_score >= 3
                  ? 'red'
                  : '',
            }}
            className="far fa-lg fa-dot-circle"
          ></i>
          {!damage.damage_name ? (
            damage.damage_location &&
            damage.damage_location.x > damage.actual_image.width / 2 ? (
              <div>
                {waiting ? (
                  <div
                    className="wait mr-2"
                    style={{ right: '100%', color: '#000' }}
                  >
                    <i className="fas fa-spinner fa-spin mr-2"></i>
                    Loading
                  </div>
                ) : null}
                <Select
                  options={typeSelectList}
                  menuPortalTarget={document.body}
                  classNamePrefix={
                    damage.damage_location &&
                    damage.damage_location.y > damage.actual_image.height / 2
                      ? 'top'
                      : 'bottom'
                  }
                  isClearable={false}
                  formatOptionLabel={formatOptionLabel}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  onChange={(e) => updateDamage(e, i)}
                  className="react-select-damage-left"
                />
              </div>
            ) : (
              <div>
                {waiting ? (
                  <div className="wait" style={{ left: '100%', color: '#000' }}>
                    <i className="fas fa-spinner fa-spin mr-2"></i>
                    Loading
                  </div>
                ) : null}
                <Select
                  options={typeSelectList}
                  isClearable={false}
                  menuPortalTarget={document.body}
                  classNamePrefix={
                    damage.damage_location &&
                    damage.damage_location.y > damage.actual_image.height / 2
                      ? 'top'
                      : 'bottom'
                  }
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  formatOptionLabel={formatOptionLabel}
                  onChange={(e) => updateDamage(e, i)}
                  className="react-select-damage-right"
                />
              </div>
            )
          ) : null}
        </div>,
      );
    }
    if (
      taskInspect.photoCode === '01' ||
      taskInspect.photoCode === '02' ||
      taskInspect.photoCode === '03' ||
      taskInspect.photoCode === '06' ||
      taskInspect.photoCode === '15'
    ) {
      return (
        <>
          <div className="tool-image">
            {checkTest ? <Testing /> : null}
            <CountDown
              countTimer={countTimer}
              plusTime={plusTime}
              handlePlusTime={handlePlusTime}
              inspectName={inspectName}
            ></CountDown>
            <div
              className={`img-full ${
                parseInt(taskInspect.photoCode, 10) === 1 && 'img-full-vin'
              }`}
              id="imgProcess"
            >
              <PrismaZoom
                className="App-zoom"
                style={{ overflow: 'scroll' }}
                minZoom={1}
                maxZoom={scale}
                leftBoundary={0}
                topBoundary={0}
                onZoomChange={(e) => props.onZoomChange(e)}
                ref={prismaZoom}
              >
                <img style={styles.img} src={img} alt="" />
              </PrismaZoom>
            </div>
            {toolHotKey()}
          </div>
          {vehicle ? (
            <div
              className="info mt-2 mb-2"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                className="group-info"
                style={{
                  background: '#fff',
                  padding: '5px 25px',
                  fontSize: '17px',
                  color: 'red',
                  borderRadius: '14px',
                  textTransform: 'uppercase',
                }}
              >
                <span className="text-head">
                  {vehicle && vehicle.year ? vehicle.year : ''}-
                  {vehicle && vehicle.make ? vehicle.make : ''}-
                  {vehicle && vehicle.model ? vehicle.model : ''}-
                  {vehicle && vehicle.bodyType ? vehicle.bodyType : ''}
                </span>
              </div>
            </div>
          ) : null}
        </>
      );
    } else {
      return (
        <div>
          <div className="tool-image" style={{ display: 'flex' }}>
            {checkTest ? <Testing /> : null}

            <div
              className="img-full"
              id="imgProcess"
              onDoubleClick={(e) => addDamage(e)}
            >
              <CountDown
                countTimer={countTimer}
                plusTime={plusTime}
                handlePlusTime={handlePlusTime}
                inspectName={inspectName}
              ></CountDown>
              {tableDamage !== 'bmw' &&
              tableDamage !== 'autograde' &&
              showModalMaterial
                ? modalMaterial()
                : null}
              <PrismaZoom
                className="App-zoom"
                minZoom={1}
                maxZoom={scale}
                onZoomChange={(e) => props.onZoomChange(e)}
                ref={prismaZoom}
                style={{ height: '100%' }}
              >
                <img style={styles.img} src={img} alt="" />
                {checkLeftRight()}
                <Rnd
                  className="rnd-cage "
                  style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '0 0',
                    backgroundSize: 'contain',
                    cursor: mate && mate.url ? mate.url : '',
                  }}
                  onDrag={(e, d) => {
                    props.resizeXY({
                      x: d.x,
                      y: d.y,
                    });
                  }}
                  onResize={(e, direction, ref, delta, position) => {
                    const width = ref.style.width;
                    const height = ref.style.height;
                    const x = position.x;
                    const y = position.y;
                    props.resizeTop(width, height, x, y);
                  }}
                  size={{
                    width: cagePo.width,
                    height: cagePo.height,
                  }}
                  position={{
                    x: cagePo.x,
                    y: cagePo.y,
                  }}
                  //bounds=".img-full"
                  lockAspectRatio={true}
                  disableDragging={disableDragging}
                >
                  {svg ? (
                    <SVG
                      className={'imageHolder hoverable svg '}
                      src={svg}
                      uniquifyIDs={false}
                    ></SVG>
                  ) : null}
                </Rnd>
                {damagePoint}
              </PrismaZoom>
            </div>
            {tableDamage !== 'bmw' && tableDamage !== 'autograde'
              ? toolMaterial()
              : null}
            {toolHotKey()}
          </div>
          {vehicle ? (
            <div
              className="info mt-2 mb-2"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                className="group-info"
                style={{
                  background: '#fff',
                  padding: '5px 25px',
                  fontSize: '17px',
                  color: 'red',
                  borderRadius: '14px',
                  textTransform: 'uppercase',
                }}
              >
                <span className="text-head">
                  {vehicle && vehicle.year ? vehicle.year : ''}-
                  {vehicle && vehicle.make ? vehicle.make : ''}-
                  {vehicle && vehicle.model ? vehicle.model : ''}-
                  {vehicle && vehicle.bodyType ? vehicle.bodyType : ''}
                </span>
              </div>
            </div>
          ) : null}
        </div>
      );
    }
  };

  return <div>{showImageFull()}</div>;
};

export default PhotoFull;
