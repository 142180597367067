import React from 'react';
import PropTypes from 'prop-types';
import IntlRelativeFormat from 'intl-relativeformat';

const NoteItem = ({ index, data, settings }) => {
	const rf = new IntlRelativeFormat('en');
	const { listTypeToRequestReview } = settings;
	let cardTitleClass;
	let cardTitle = data.type;
	if (
		listTypeToRequestReview &&
		listTypeToRequestReview.includes(data.type)
	) {
		cardTitleClass = 'text-danger';
	} else {
		cardTitleClass = 'text-info';
	}
	return (
		<div key={data._id} className={`card text-center`}>
			<div className="card-body">
				<h5 className={`card-title ${cardTitleClass}`}>
					{index}. {cardTitle}
				</h5>
				<p className="card-text">{data.message}</p>
				<p className="card-text">
					<small className="text-logan">
						{rf.format(new Date(data.createdAt))} by{' '}
						<b>{data.owner ? data.owner.username : 'system'}</b>
					</small>
				</p>
			</div>
		</div>
	);
};

NoteItem.propTypes = {
	index: PropTypes.number,
	data: PropTypes.object.isRequired
};

export default NoteItem;
