import React from 'react';

const FinishButton = (props) => {
  return (
    <button
      key={`Finish`}
      type="button"
      className="btn btn-success mr-1"
      style={{ width: '100%' }}
      onClick={() =>
        props.showModalBtn(
          'Finish',
          'Finish',
          'Are You Sure Want to Finish This Image',
        )
      }
    >
      <i className="fa fa-check mr-2 check-icon" aria-hidden="true" />
      Finish
    </button>
  );
};

export default FinishButton;
