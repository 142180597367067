import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
Sentry.init({
	dsn: 'https://08a2fd39f9ae4094b764a63d4986124f@sentry.io/1894889',
	environment: process.env.NODE_ENV,
	integrations: [new TracingIntegrations.BrowserTracing()],

	// To set a uniform sample rate
	tracesSampleRate: 0.04,
});

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
