import React from 'react';

export default ({ user }) => {
	return (
		<div className="white box">
			<h2 className="display-4 text-secondary-border mb-4">Profile</h2>
			<div className="row">
				<div className="col-4">
					<div className="img-thumbnail">
						<img
							src="/img/logo_dlvn.png"
							width="100%"
							height="auto"
							alt="{user.username}"
						/>
					</div>
				</div>
				<div className="col-8">
					<table className="table table-sm table-baseline">
						<tbody>
							<tr>
								<th>Email Address</th>
								<td>{user.email}</td>
							</tr>
							<tr>
								<th>Username</th>
								<td>{user.username}</td>
							</tr>
							<tr>
								<th>Role</th>
								<td>{user.role}</td>
							</tr>

							<tr>
								<th>Sex</th>
								<td>{user.sex}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};
