import React from 'react';

const NoteModal = ({ cage, data, errors, onSubmitNote, onChangeNote }) => {
	return (
		<div
			className="modal fade show"
			id="Add_note"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="Add_note_title"
			aria-hidden="true"
		>
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title text-uppercase"
							id="Add_note_title"
						>
							Add Note
						</h5>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<i className="fa fa-times" />
						</button>
					</div>
					<div className="modal-body">
						<p className="display-6 text-secondary">
							Agent can only submit{' '}
							<span className="text-head text-info">INFORM</span>{' '}
							note
						</p>
						<div className="form-group">
							<label htmlFor="Note_message">Message</label>
							<textarea
								className={`form-control ${
									errors.message ? 'is-invalid' : ''
								}`}
								name="message"
								onChange={onChangeNote}
								value={data.message}
							/>
							{errors.message && (
								<div className="invalid-feedback">
									{errors.message}
								</div>
							)}
						</div>

						<button
							onClick={onSubmitNote}
							className="btn btn-warning"
						>
							<span>Submit a Note</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NoteModal;
