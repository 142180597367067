import React from 'react';

const CarAni = () => {
	return (
		<div className="ani-car">
			<div className="box-car">
				<div className="circle"></div>
				<div className="car">
					<div className="body">
						<div className="mirror-wrap">
							<div className="mirror-inner">
								<div className="mirror">
									<div className="shine" />
								</div>
							</div>
						</div>
						<div className="middle">
							<div className="top">
								<div className="line" />
							</div>
							<div className="bottom">
								<div className="lights">
									<div className="line" />
								</div>
							</div>
						</div>
						<div className="bumper">
							<div className="top" />
							<div className="middle" data-numb="बा ९५ च ९५९५" />
							<div className="bottom" />
						</div>
					</div>
					<div className="tyres">
						<div className="tyre back" />
						<div className="tyre front" />
					</div>
				</div>
			</div>
			<div className="road-wrap">
				<div className="road">
					<div className="lane-wrap">
						<div className="lane">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CarAni;
