export const listMake = [
  'Acura',
  'Asüna',
  'AMC Eagle',
  'Alfa Romeo',
  'Aston Martin',
  'Audi',
  'Bentley',
  'BMW',
  'Bugatti',
  'Buick',
  'Bristol',
  'Cadillac',
  'Chevrolet',
  'Chrysler',
  'Daihatsu',
  'Dodge',
  'RAM',
  'Daewoo',
  'Ferrari',
  'Fiat',
  'Ford',
  'GMC',
  'Honda',
  'Hummer',
  'Hyundai',
  'Genesis',
  'Infiniti',
  'Isuzu',
  'Jaguar',
  'Jeep',
  'Kia',
  'Lada',
  'Lamborghini',
  'Land Rover',
  'Lexus',
  'Lincoln',
  'Lotus',
  'Maserati',
  'Mazda',
  'Maybach',
  'McLaren',
  'Mercedes',
  'MINI',
  'Mitsubishi',
  'Mercury',
  'Morgan',
  'Nissan',
  'Panther',
  'Peugeot',
  'Oldsmobile',
  'Plymouth',
  'Porsche',
  'Pontiac',
  'Rolls-Royce',
  'Saab',
  'Saturn',
  'Scion',
  'Smart',
  'Subaru',
  'Suzuki',
  'Spyker',
  'Tesla',
  'Toyota',
  'Volkswagen',
  'Volvo',
  'Zastava',
  'Seat',
  'Freightliner',
  'Hino',
  'Rivian',
  'Default',
  'Standard',
];

export const listBodyType = [
  '2dr_Car',
  '4dr_Car',
  'Convertible',
  'Crew_Cab_Pickup',
  'Ext_Cab_Pickup',
  'Mini_Van_Cargo',
  'Mini_Van_Passenger',
  'Reg_Cab_Pickup',
  'Sport_Utility',
  'Station_Wagon',
  'Tractor_Trailer',
  'No_Define_Outline',
  'Cutaway',
  'Chassis',
  'Default',
];

export const listYear = {
  min: 1990,
  max: new Date(
    new Date().setFullYear(new Date().getFullYear() + 1),
  ).getFullYear(),
};

export const listStatus = [
  'PROCESS-PHOTO',
  'PROCESS-CAGE',
  'PROCESS-LABEL',
  'REQUEST-REVIEW',
];

export const defCageDetailOptions = {
  photo: {
    icon: 'fa fa-image',
    title: 'Photo',
  },
  cage: {
    icon: 'fa fa-lg fa-car',
    title: 'Cage',
  },
  label: {
    icon: 'fa fa-lg fa-car',
    title: 'Cage Labelled',
  },
  outline: {
    icon: 'fa fa-lg fa-car',
    title: 'Outline',
  },
};

export const cageHost = 'http://vehicles.pave.bot';
