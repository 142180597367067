import axios from 'axios';
import { store } from '../store';
import uiActions from '../app/ui/actions';
import { logout } from '../app/auth/operations';

export const exec = async (
	type,
	url,
	data,
	callback,
	loading = true,
	header = {}
) => {
	let success = false;
	if (loading) {
		store.dispatch(uiActions.setLoading());
	}
	try {
		const instance = axios.create({
			headers: header,
		});
		if (!['get', 'post', 'put', 'delete'].includes(type)) {
			const error = {
				response: { status: 405, message: 'Unsupported method.' },
			};
			throw error;
		}
		let req;
		if (type === 'get') {
			req = await instance.get(url, { params: data || {} });
		} else {
			req = await instance[type](url, data);
		}
		if (req.data) {
			callback(req.data);
			if (req.data.message) {
				store.dispatch(
					uiActions.setResponse({
						response: {
							status: req.data.status || req.status,
							message: req.data.message,
						},
					})
				);
			}
			success = true;
		}
	} catch (e) {
		if (
			e.response &&
			e.response.data &&
			e.response.data === 'Unauthorized'
		) {
			store.dispatch(logout());
		}
		store.dispatch(uiActions.setErrors(e));
	}
	if (loading) {
		store.dispatch(uiActions.clearLoading());
	}
	return success;
};
