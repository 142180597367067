import React, { Component } from 'react';
import Back from './components/Back';
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListTab from './components/ListTab';
import TabContent from './components/TabContent';

class Label extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			bodyData: [],
			position: 'Left'
		};
	}
	changePosition = position => {
		this.setState({
			position: position
		});
	};
	render() {
		const Data = require(`../label/BodyTypes.json`) || [];
		let { bodyData, position } = this.state;
		bodyData = Data ? Data : null;
		return (
			<div id="label">
				<Back />
				<Tab.Container id="left-tabs-example" defaultActiveKey="2">
					<Row>
						<Col md={2}>
							<ListTab bodyData={bodyData} />
						</Col>
						<Col md={10}>
							<TabContent
								bodyData={bodyData}
								position={position}
								changePosition={position =>
									this.changePosition(position)
								}
							/>
						</Col>
					</Row>
				</Tab.Container>
			</div>
		);
	}
}

export default Label;
