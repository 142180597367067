import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = (props) => {
	let auth = props.auth;
	let Component = props.component;
	return (
		<Fragment>
			<Route
				render={(props) =>
					auth.isAuthenticated ? (
						<Component {...props} />
					) : (
						<Redirect to="/" />
					)
				}
			/>
		</Fragment>
	);
};

PrivateRoute.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
