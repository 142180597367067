import React from 'react';
import PropTypes from 'prop-types';
import CageListTableItem from './CageListTableItem';

const CageListTable = ({ data, filters, onChange, settings }) => {
	const { listMake, listBodyType, listYear, listStatus } = settings;
	const tblHeaders = (
		<tr>
			<th width="8%" />
			<th width="7%">Type</th>
			<th width="10%">Make</th>
			<th width="15%">Model</th>
			<th width="5%">Year</th>
			<th width="10%">Body</th>
			<th width="15%">Trim</th>
			<th width="10%">Code</th>
			<th width="10%">Status</th>
			<th width="10%" />
		</tr>
	);

	let selectYears = [];
	if (listYear) {
		for (let i = listYear.max; i >= listYear.min; i--) {
			selectYears.push(
				<option key={`Year_${i}`} value={i}>
					{i}
				</option>
			);
		}
	}

	const tblFilters = (
		<tr>
			<th />
			<th>
				<select
					className="form-control form-control-sm"
					name="group"
					defaultValue={filters.group}
					onChange={onChange}
				>
					<option value="">( all )</option>
					<option value="A">A</option>
					<option value="B">B</option>
				</select>
			</th>
			<th>
				<select
					className="form-control form-control-sm"
					name="make"
					defaultValue={filters.make}
					onChange={onChange}
				>
					<option value="">( all )</option>
					{listMake &&
						listMake.map(item => (
							<option key={item} value={item}>
								{item}
							</option>
						))}
				</select>
			</th>
			<th>
				<input
					type="text"
					className="form-control form-control-sm"
					name="model"
					defaultValue={filters.model}
					onChange={onChange}
				/>
			</th>
			<th>
				<select
					className="form-control form-control-sm"
					name="year"
					defaultValue={filters.year}
					onChange={onChange}
				>
					<option value="">( all )</option>
					{selectYears}
				</select>
			</th>
			<th>
				<select
					className="form-control form-control-sm"
					name="bodyType"
					defaultValue={filters.bodyType}
					onChange={onChange}
				>
					<option value="">( all )</option>
					{listBodyType &&
						listBodyType.map(item => (
							<option key={item} value={item}>
								{item}
							</option>
						))}
				</select>
			</th>
			<th>
				<input
					type="text"
					className="form-control form-control-sm"
					name="trim"
					defaultValue={filters.trim}
					onChange={onChange}
				/>
			</th>
			<th>
				<input
					type="text"
					className="form-control form-control-sm"
					name="code"
					defaultValue={filters.code}
					onChange={onChange}
				/>
			</th>
			<th>
				<select
					className="form-control form-control-sm"
					name="status"
					defaultValue={filters.status}
					onChange={onChange}
				>
					<option value="">( all )</option>
					{listStatus &&
						listStatus.map(item => (
							<option key={item} value={item}>
								{item}
							</option>
						))}
				</select>
			</th>
		</tr>
	);

	let tblBody;
	if (data.length > 0) {
		tblBody = data.map(item => {
			return (
				<CageListTableItem key={`Cage_item_${item._id}`} data={item} />
			);
		});
	} else {
		tblBody = (
			<tr>
				<td colSpan="9" className="text-secondary">
					<b>No cage found.</b>
				</td>
			</tr>
		);
	}

	return (
		<table id="Cage_list_table" className="table table-sm table-bordered">
			<thead className="thead-dark">{tblHeaders}</thead>
			<thead className="thead-light">{tblFilters}</thead>
			<tbody>{tblBody}</tbody>
		</table>
	);
};

CageListTable.propTypes = {
	data: PropTypes.array.isRequired,
	filters: PropTypes.object,
	onChange: PropTypes.func
};

export default CageListTable;
