import React, { useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';

const initDate = (value) => {
  let localTzDate = moment(value);
  return {
    input: localTzDate.toDate(),
    local: localTzDate.format('YYYY-MM-DD'),
  };
};

const DateRangePicker = (props) => {
  const { label, value, name, error, onChange } = props;
  const [date, setDate] = useState({ input: {}, localTz: {} });

  useMemo(() => {
    if (!value) return false;

    const date =
      typeof value === 'object' ? value : { from: new Date(), to: new Date() };
    const initDateFrom = initDate(date.from);
    const initDateTo = initDate(date.to);

    setDate({
      input: { from: initDateFrom.input, to: initDateTo.input },
      localTz: { from: initDateFrom.local, to: initDateTo.local },
    });
  }, [value]);

  const onDateChange = (key, newDate) => {
    const localTzDate = moment(newDate);
    const newLocalTz = {
      ...date.localTz,
      [key]: localTzDate.format('YYYY-MM-DD,HH:mm'),
    };
    setDate({
      ...date,
      input: { ...date.input, [key]: newDate },
      localTz: newLocalTz,
    });
    onChange({ target: { name, value: newLocalTz } });
  };

  return (
    <div className="form-group">
      {label && (
        <label className="text-head text-uppercase text-logan display-7">
          {label}
        </label>
      )}
      <div className="row">
        <div className="col-6">
          <div className="text-head text-uppercase text-muted mb-1">
            <small>From</small>
          </div>
          <DatePicker
            selected={(date && date.input && date.input.from) || false}
            startDate={date && date.input && date.input.from}
            endDate={date && date.input && date.input.to}
            timeIntervals={60}
            showTimeSelect
            dateFormat="yyyy-MM-dd,HH:mm"
            timeFormat="HH:mm"
            className={`form-control ${error ? 'is-invalid' : ''}`}
            onChange={(date) => {
              onDateChange('from', date);
            }}
            selectsStart
          />
        </div>
        <div className="col-6">
          <div className="text-head text-uppercase text-muted mb-1">
            <small>To</small>
          </div>
          <DatePicker
            selected={date && date.input && date.input.to}
            timeIntervals={60}
            startDate={date && date.input && date.input.from}
            endDate={date && date.input && date.input.to}
            dateFormat="yyyy-MM-dd,HH:mm"
            timeFormat="HH:mm"
            showTimeSelect
            className={`form-control ${error ? 'is-invalid' : ''}`}
            onChange={(date) => {
              onDateChange('to', date);
            }}
            selectsEnd
          />
        </div>
      </div>
      {error && <div className="small text-danger mt-2">{error}</div>}
    </div>
  );
};

export default DateRangePicker;
