import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllCages } from '../operations';
import { CageListTable } from './components';
import Pagination from '../../../components/common/Pagination';

class CageList extends Component {
	constructor(props) {
		super(props);
		const { filters, limit, current, pages, count } = this.props.cage.list;
		this.state = {
			autoRefresh: false,
			filters: filters,
			limit: limit,
			current: current,
			pages: pages,
			count: count,
		};
		this.onChange = this.onChange.bind(this);
		this.onPageChange = this.onPageChange.bind(this);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.cage.list) {
			const {
				filters,
				limit,
				current,
				pages,
				count,
			} = nextProps.cage.list;
			this.setState({
				filters,
				limit,
				current,
				pages,
				count,
			});
		}
	}
	UNSAFE_componentWillMount() {
		this.props.getAllCages({ filters: this.state.filters });
	}

	onPageChange(e) {
		e.preventDefault();
		let limit = this.state.limit;
		if (e.target.getAttribute('data-value')) {
			limit = parseInt(
				e.target.getAttribute('data-value') || this.state.limit,
				10
			);
		}
		let page;
		if (e.target.getAttribute('data-page')) {
			page = parseInt(e.target.getAttribute('data-page'), 10);
		} else if (e.target.value) {
			page = parseInt(e.target.value, 10);
		}
		this.props.getAllCages({
			page: page,
			limit: limit,
			filters: this.state.filters,
		});
		this.setState({
			page,
			limit,
		});
	}

	onToggle(e) {
		const state = !this.state[e.target.name];
		this.setState({ [e.target.name]: state });
	}

	onChange(e) {
		let filters = this.state.filters;
		filters[e.target.name] = e.target.value;
		this.props.getAllCages({ filters: filters, limit: this.state.limit });
		this.setState({ filters: filters });
	}

	render() {
		const { list, settings } = this.props.cage;
		const { limit, current, pages, count } = this.state;
		return (
			<div id="Cage_list">
				<div className="container-fluid">
					<h2 className="subtitle">Cage Manager</h2>
					{list.pages > 1 && (
						<Pagination
							size={limit}
							records={count}
							pages={pages}
							page={current}
							action={this.onPageChange}
						/>
					)}
					<CageListTable
						data={list.data}
						filters={this.state.filters}
						onChange={this.onChange}
						settings={settings}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	cage: state.cage,
});

export default connect(mapStateToProps, { getAllCages })(CageList);
