import * as types from './types';
import { GET_ERRORS, CLEAR_ERRORS } from '../../actions/types';

const setCurrentUser = (decoded) => {
	return {
		type: types.SET_CURRENT_USER,
		payload: decoded,
	};
};

const clearCurrentUser = () => {
	return {
		type: types.CLEAR_CURRENT_USER,
	};
};

const clearResponse = () => {
	return {
		type: types.CLEAR_RESPONSE,
	};
};

const changeProfile = (data) => {
	return {
		type: types.CHANGE_PROFILE,
		payload: data,
	};
};

const hasError = (e) => ({
	type: GET_ERRORS,
	payload: e.response,
});

const clearErrors = () => {
	return {
		type: CLEAR_ERRORS,
	};
};

export default {
	setCurrentUser,
	clearCurrentUser,
	changeProfile,
	hasError,
	clearErrors,
	clearResponse,
};
