import React, { useEffect, useState } from 'react';
import Hotkeys from 'react-hot-keys';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllTaskFailed, deleteTaskFailed } from '../operations';
import Pagination from '../../../components/common/Pagination';
import TaskFailedTable from './components/TaskFailedTable';

const TaskFailedList = (props) => {
  const { user } = props.auth;
  const { list } = props.taskFailed;
  const { success } = props.taskFailed;
  let [filters, setfilters] = useState({});
  const [errors, setErrors] = useState({});
  const [maxDays] = useState(92);
  const [optionSelectType, setoptionSelectType] = useState('damage');
  let [limit, setlimit] = useState(50);
  let [page, setpage] = useState(1);
  const pages = list && list.pages ? list.pages : 1;
  const count = list && list.count ? list.count : 1;
  const total = list && list.total ? list.total : 1;
  const listStatusTaskFailed = [
    'INSPECTOR-REVIEWING',
    'QA-REVIEWING',
    'VERIFYING',
    'VERIFIED',
    'INSPECTOR-AUTO-VERIFIED',
    'QA-AUTO-VERIFIED',
  ];
  const skills = [
    'vin',
    'trim_id',
    'ext_id',
    'damage_full',
    'damage_other',
    'damage_body',
    'qc',
    'plate_number',
    'odom',
    'interior',
    'tire',
    'glass',
    'qc_plate',
  ];
  const timezone =
    user && user.options && user.options.timezone
      ? user.options.timezone
      : 'Asia/Ho_Chi_Minh';
  useEffect(() => {
    const filter = filters;
    if (user.role === 'AGENT' && !filter.status) {
      filter['status'] = 'INSPECTOR-REVIEWING';
      filter['inspector'] = user.username;
      setfilters(filter);
    }
    props.getAllTaskFailed({
      filters,
      page: page,
      limit: limit,
    });
    // eslint-disable-next-line
  }, [filters, success]);
  const onPageChange = (e) => {
    e.preventDefault();
    if (e.target.getAttribute('data-value')) {
      limit = parseInt(e.target.getAttribute('data-value') || limit, 10);
    }
    if (e.target.getAttribute('data-page')) {
      page = parseInt(e.target.getAttribute('data-page'), 10);
    } else if (e.target.value) {
      page = parseInt(e.target.value, 10);
    }
    props.getAllTaskFailed({
      page: page,
      limit: limit,
      filters: filters,
    });
    setpage(page);
    setlimit(limit);
  };
  const onChange = (e) => {
    let filter = filters;
    filter[e.target.name] = e.target.value;
    setfilters(filter);
  };
  const onChangeApi = (e) => {
    e.preventDefault();
    let filter = filters;
    filter[e.target.name] = e.target.value;
    props.getAllTaskFailed({
      filters: filter,
      limit: limit,
    });
    setpage(1);
    setfilters(filter);
  };
  const toolHotKey = () => {
    return (
      <div className="tool-cage" style={{ display: 'none' }}>
        <Hotkeys
          keyName="return,enter"
          onKeyDown={(e) => onSubmit(e)}
        ></Hotkeys>
      </div>
    );
  };
  const handleKeyPress = (e) => {
    const key = e.keyCode || e.which;
    if (key === 13) {
      onSubmit();
    }
  };
  const changeOptionSelectType = (e) => {
    setoptionSelectType(e.target.value);
  };
  const onSubmit = () => {
    //e.preventDefault();
    const { from, to } = filters.range || {};
    const start = moment(from || new Date());
    const end = moment(to || new Date());
    const days = end.diff(start, 'days');

    if (days > maxDays) {
      setErrors({
        ...errors,
        range: `Date range exceeded maximum of ${maxDays} days.`,
      });
    } else {
      let filter = {
        ...filters,
        timezone: timezone,
      };
      setfilters(filter);
      props.getAllTaskFailed({
        filters: filter,
        limit: limit,
      });
      setpage(1);
    }
  };
  const deleteTaskFailed = (id) => {
    console.log(id);
    const data = {
      id: id,
    };
    props.deleteTaskFailed(data).then((res) => {
      if (res) {
        let filter = filters;
        props.getAllTaskFailed({
          filters: filter,
          limit: limit,
          page: page,
        });
      }
    });
  };

  return (
    <div id="User_list">
      <div className="container-fluid">
        {toolHotKey()}
        <h2 className="subtitle">
          <small>
            <Link to="/performance" className="text-logan">
              <i className="fa fa-arrow-left" />
              <span>List Performance</span>
            </Link>
          </small>
        </h2>
        <h2 className="subtitle">List TaskFailed</h2>
        <div
          style={{
            position: 'fixed',
            right: '20px',
            bottom: '5px',
            zIndex: '9999',
          }}
        >
          <Pagination
            size={limit}
            records={count}
            pages={pages}
            page={page}
            action={onPageChange}
            total={total}
          />
        </div>

        <TaskFailedTable
          data={list.data}
          deleteTaskFailed={deleteTaskFailed}
          filters={filters}
          onChange={onChange}
          onChangeApi={onChangeApi}
          timezone={timezone}
          listStatusTaskFailed={listStatusTaskFailed}
          optionSelectType={optionSelectType}
          user={user}
          errors={errors}
          skills={skills}
          onSubmit={onSubmit}
          handleKeyPress={handleKeyPress}
          changeOptionSelectType={changeOptionSelectType}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  setting: state.setting,
  taskFailed: state.taskFailed,
});

export default connect(mapStateToProps, {
  getAllTaskFailed,
  deleteTaskFailed,
})(TaskFailedList);
