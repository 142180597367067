import React, { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerIcon from '../../ui/containers/SpinnerIcon';

class Loading extends Component {
	render() {
		const { loading } = this.props.ui;

		return (
			<div id="Loading" className={`${loading ? 'd-flex' : 'd-none'}`}>
				<div className="align-self-center w-100">
					<SpinnerIcon />
					<div className="display-8 text-uppercase text-head text-logan text-center mt-1">
						<span className="text-dark">PAVE</span> is hardworking
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	ui: state.ui,
});

export default connect(mapStateToProps, {})(Loading);
