import React, { Component } from 'react';
import { connect } from 'react-redux';
import LinkInstruction from './components/LinkInstruction';
class Intruction extends Component {
  render() {
    return (
      <div className="container text-center">
        <div id="intruction" className="mt-2">
          <div className="row">
            <div className="col-md-12">
              <LinkInstruction
                link="label"
                icon="fas fa-car-side"
                text="Labeling"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(Intruction);
