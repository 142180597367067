import React from 'react';
import SVG from 'react-inlinesvg';
const ModalTimeOut = (props) => {
  return (
    <div className={`modal timeout show-modal`} id="myModal">
      <div className="modal-dialog false">
        <div className="modal-content">
          <div className="modal-body">
            <div className="group-timeout">
              <div className="group-text">
                <div className="layout"></div>
                <h3>Time OUT !!!</h3>
                <span>Hey Try your best next time !!!</span>
                <button
                  type="button"
                  className={`btn`}
                  onClick={() => props.timeOut()}
                >
                  Back To Start
                </button>
              </div>
              <SVG
                className={'imageHolder'}
                src={'/img/svg/oops.svg'}
                uniquifyIDs={false}
              ></SVG>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalTimeOut;
