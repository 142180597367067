import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LinkIntrucstion from './components/LinkInstruction';
const Performance = (props) => {
  const { user } = props.auth;
  const [menu, setmenu] = useState([
    {
      id: 'listTaskFailed',
      icon: 'fas fa-pencil-ruler',
      path: '/performance/listTaskFailed',
      label: 'Task Failed',
    },
    {
      id: 'statistic',
      icon: 'fas fa-pencil-ruler',
      path: '/performance/statistic',
      label: 'Statistic',
    },
  ]);
  useEffect(() => {
    if (user.role === 'TRAINEE') {
      setmenu([
        {
          id: 'listTaskFailed',
          icon: 'fas fa-pencil-ruler',
          path: '/performance/listTaskFailed',
          label: 'Task Failed',
        },
      ]);
    }
  }, []);
  const renderListPerformance = () => {
    const width = 100 / menu.length;
    return menu.map((item) => {
      return (
        <div
          key={`Performance_menu_${item.id}`}
          style={{
            width: `${width}%`,
          }}
          className="mr-3"
        >
          <LinkIntrucstion
            link={item.path}
            icon={item.icon}
            text={item.label}
          />
        </div>
      );
    });
  };
  return (
    <div id="intruction" className="container" style={{ display: 'flex' }}>
      {renderListPerformance()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  ui: state.ui,
});

export default connect(mapStateToProps, {})(Performance);
