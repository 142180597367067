import React from 'react';

const durationSegment = (value) => {
  const hours = Math.floor(value / 3600) || '--';
  let mins = Math.floor(value / 60) || '--';
  if (value >= 3600) {
    mins = Math.floor((value % (hours * 3600)) / 60) || '--';
  }
  return { hours, mins };
};

const InfomationTask = (props) => {
  const { sumTaskTotal, textTotal } = props;
  const { byDate, today } = props.sumTaskTotal;
  const todayStats = (byDate && today && byDate[today]) || {};
  const todayDutyTime = todayStats.onDuty || 0;
  const todayInspectTime = todayStats.onInspect || 0;
  const todayTimeout = todayStats.hasTimeout ? todayStats.hasTimeout : 0;
  const todayInspectTimeout = todayStats.hasInspectTimeout
    ? todayStats.hasInspectTimeout
    : 0;
  const todayHandle = todayStats.handle ? todayStats.handle : 0;
  const todayPlusTime = todayStats.plusTime ? todayStats.plusTime : 0;
  const todayByType = todayStats.byType || {};
  const todayAccepted = todayByType.accepted ? todayByType.accepted : 0;
  const todayRejected = todayByType.rejected ? todayByType.rejected : 0;
  const todayInspect = todayByType.inspect ? todayByType.inspect : 0;
  const todayWorkTime = durationSegment(todayDutyTime + todayInspectTime);
  const byType = sumTaskTotal.byType || {};
  const plusTime = sumTaskTotal.plusTime ? sumTaskTotal.plusTime : 0;
  const hasTimeout = sumTaskTotal.hasTimeout ? sumTaskTotal.hasTimeout : 0;
  const hasInspectTimeout = sumTaskTotal.hasInspectTimeout
    ? sumTaskTotal.hasInspectTimeout
    : 0;
  const handle = sumTaskTotal.handle ? sumTaskTotal.handle : 0;
  const accepted = byType.accepted ? byType.accepted : 0;
  const rejected = byType.rejected ? byType.rejected : 0;
  const inspect = byType.inspect ? byType.inspect : 0;
  const weekDutyTime = sumTaskTotal.onDuty || 0;
  const weekInspectTime = sumTaskTotal.onInspect || 0;
  const weekWorkTime = durationSegment(weekDutyTime + weekInspectTime);

  return (
    <div className="box-info mb-3">
      <div className="box-total mb-3">
        <div className="title">{textTotal}</div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="week">
            <h2>THIS WEEK</h2>
            <div className="row">
              <div className="col-6">
                <div className="img">
                  <img
                    alt="calender"
                    style={{ width: '100%' }}
                    src="/img/calender.png"
                  ></img>
                </div>
              </div>
              <div className="col-6">
                <div className="tasks mb-2">
                  <div
                    className="count-task"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className="display-duration" style={{ width: '35%' }}>
                      <div>
                        <span>{sumTaskTotal.total || '--'}</span>
                        <span>tasks</span>
                      </div>
                    </div>
                    <div className="type">
                      <div className="accepted row mb-1">
                        <div className="col-3">
                          <span>{accepted ? accepted : 0}</span>
                        </div>
                        <div className="col-9">
                          <span style={{ color: '#32134266' }} className="">
                            Accept
                          </span>
                          <i className="fas fa-check"></i>
                        </div>
                      </div>
                      <div className="rejected row mb-1">
                        <div className="col-3">
                          <span>{rejected ? rejected : 0}</span>
                        </div>
                        <div className="col-9">
                          <span className="" style={{ color: '#32134266' }}>
                            Reject
                          </span>
                          <i className="fas fa-times"></i>
                        </div>
                      </div>
                      <div className="inspect row mb-1">
                        <div className="col-3">
                          <span>{inspect ? inspect : 0}</span>
                        </div>
                        <div className="col-9">
                          <span className="" style={{ color: '#32134266' }}>
                            Inspect
                          </span>
                          <i className="fas fa-car"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="other mb-2">
                    <div className="type">
                      <div className="handle row">
                        <div className="col-10">
                          <i className="fas fa-broom"></i>
                          <span style={{ color: '#32134266' }} className="ml-1">
                            Handle
                          </span>
                        </div>
                        <div className="col-2">
                          <span>{handle ? handle : 0}</span>
                        </div>
                      </div>
                      <div className="plus-time row">
                        <div className="col-10">
                          <i className=" fas fa-user-clock"></i>
                          <span style={{ color: '#32134266' }} className="ml-1">
                            Plus Time
                          </span>
                        </div>
                        <div className="col-2">
                          <span>{plusTime ? plusTime : 0}</span>
                        </div>
                      </div>
                      <div className="timeout-qc row">
                        <div className="col-10">
                          <i className=" fas fa-user-clock"></i>
                          <span style={{ color: '#32134266' }} className="ml-1">
                            Timeout Qc
                          </span>
                        </div>
                        <div className="col-2">
                          <span>{hasTimeout ? hasTimeout : 0}</span>
                        </div>
                      </div>
                      <div className="timeout-inspect row">
                        <div className="col-10">
                          <i className=" fas fa-user-clock"></i>
                          <span style={{ color: '#32134266' }} className="ml-1">
                            Timeout Inspect
                          </span>
                        </div>
                        <div className="col-2">
                          <span>
                            {hasInspectTimeout ? hasInspectTimeout : 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="time mt-2">
                  <div className="ml-2 display-4">TIME</div>
                  <div className="display-duration">
                    <div>
                      <span>{weekWorkTime.hours}</span>
                      <span>hours</span>
                    </div>
                    <div>
                      <span>{weekWorkTime.mins}</span>
                      <span>minutes</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="today">
            <h2>Today {today}</h2>
            <div className="row">
              <div className="col-6">
                <div className="img">
                  <img
                    alt="calender"
                    style={{ width: '100%' }}
                    src="/img/today.png"
                  ></img>
                </div>
              </div>
              <div className="col-6">
                <div className="tasks mb-2">
                  <div
                    className="count-task"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className="display-duration" style={{ width: '35%' }}>
                      <div>
                        <span>{todayStats.total || '--'}</span>
                        <span>tasks</span>
                      </div>
                    </div>
                    <div className="type">
                      <div className="accepted row mb-1">
                        <div className="col-3">
                          <span>{todayAccepted ? todayAccepted : 0}</span>
                        </div>
                        <div className="col-9">
                          <span style={{ color: '#32134266' }} className="">
                            Accept
                          </span>
                          <i className="fas fa-check"></i>
                        </div>
                      </div>
                      <div className="rejected row mb-1">
                        <div className="col-3">
                          <span>{todayRejected ? todayRejected : 0}</span>
                        </div>
                        <div className="col-9">
                          <span className="" style={{ color: '#32134266' }}>
                            Reject
                          </span>
                          <i className="fas fa-times"></i>
                        </div>
                      </div>
                      <div className="inspect row mb-1">
                        <div className="col-3">
                          <span>{todayInspect ? todayInspect : 0}</span>
                        </div>
                        <div className="col-9">
                          <span className="" style={{ color: '#32134266' }}>
                            Inspect
                          </span>
                          <i className="fas fa-car"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="other mb-2">
                    <div className="type">
                      <div className="handle row">
                        <div className="col-10">
                          <i className="fas fa-broom"></i>
                          <span style={{ color: '#32134266' }} className="ml-1">
                            Handle
                          </span>
                        </div>
                        <div className="col-2">
                          <span>{todayHandle ? todayHandle : 0}</span>
                        </div>
                      </div>
                      <div className="plus-time row">
                        <div className="col-10">
                          <i className=" fas fa-user-clock"></i>
                          <span style={{ color: '#32134266' }} className="ml-1">
                            Plus Time
                          </span>
                        </div>
                        <div className="col-2">
                          <span>{todayPlusTime ? todayPlusTime : 0}</span>
                        </div>
                      </div>
                      <div className="timeout-qc row">
                        <div className="col-10">
                          <i className=" fas fa-user-clock"></i>
                          <span style={{ color: '#32134266' }} className="ml-1">
                            Timeout Qc
                          </span>
                        </div>
                        <div className="col-2">
                          <span>{todayTimeout ? todayTimeout : 0}</span>
                        </div>
                      </div>
                      <div className="timeout-inspect row">
                        <div className="col-10">
                          <i className=" fas fa-user-clock"></i>
                          <span style={{ color: '#32134266' }} className="ml-1">
                            Timeout Inspect
                          </span>
                        </div>
                        <div className="col-2">
                          <span>
                            {todayInspectTimeout ? todayInspectTimeout : 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="time mt-2">
                  <div className="ml-2 display-4">TIME</div>
                  <div className="display-duration">
                    <div>
                      <span>{todayWorkTime.hours}</span>
                      <span>hours</span>
                    </div>
                    <div>
                      <span>{todayWorkTime.mins}</span>
                      <span>minutes</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfomationTask;
