export const GET_MISSION = 'app/inspection/GET_MISSION';
export const PUT_MISSION = 'app/inspection/PUT_MISSION';
export const PROCESSING = 'app/inspection/PROCESSING';
export const LOADING = 'app/inspection/LOADING';
export const HAS_ERROR = 'app/inspection/HAS_ERROR';
export const STATUS_WAIT = 'app/inspection/STATUS_WAIT';
export const STATUS_PROCESS = 'app/inspection/STATUS_PROCESS';
export const STATUS_START = 'app/inspection/STATUS_START';
export const REJECT_IMAGE = 'app/inspection/REJECT_IMAGE';
export const ACCEPT_IMAGE = 'app/inspection/ACCEPT_IMAGE';
export const FINISH_IMAGE = 'app/inspection/FINISH_IMAGE';
export const ADMIN_FINISH = 'app/inspection/ADMIN_FINISH';
export const COUNT_TIME = 'app/inspection/COUNT_TIME';
export const COUNT_TIMETASK = 'app/inspection/COUNT_TIMETASK';
export const SET_TIME = 'app/inspection/SET_TIME';
export const TIME_OUT = 'app/inspection/TIME_OUT';
export const GET_TASKSLOT = 'app/inspection/GET_TASKSLOT';
export const INFO_USER = 'app/inspection/INFO_USER';
export const RESET_INSPECTION = 'app/inspection/RESET_INSPECTION';
export const SUMARY_TASK_USER = 'app/inspection/SUMARY_TASK_USER';
export const GET_DAMAGES = 'app/inspection/GET_DAMAGES';
export const CLEAR_LIST_DAMAGES = 'app/inspection/CLEAR_LIST_DAMAGES';
export const CLEAR_DATA_TASK = 'app/inspection/CLEAR_DATA_TASK';
export const DOING_TASK = 'app/inspection/DOING_TASK';
export const CLEAR_MESSAGE = 'app/inspection/CLEAR_MESSAGE';
export const PLUS_TIME = 'app/inspection/PLUS_TIME';
export const CLEAR_ERROR = 'app/inspection/CLEAR_ERROR';
export const CHANGE_URL_ANIMATION = 'app/inspection/CHANGE_URL_ANIMATION';
