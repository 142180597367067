import * as types from './types';
import isEmpty from '../../validation/is-empty';

const initialState = {
  isAuthenticated: false,
  user: {},
  response: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case types.UPDATE_TASKFAILED:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case types.CLEAR_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };
    case types.CLEAR_RESPONSE:
      return {
        ...state,
        response: {},
      };
    case types.CHANGE_PROFILE:
      return {
        ...state,
        response: action.payload,
      };
    default:
      return state;
  }
}
