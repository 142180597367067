import React from 'react';
import PropTypes from 'prop-types';
import PrismaZoom from 'react-prismazoom';

const FileModal = ({ id, title, src, bgColor }) => {
	let modalBodyStyle = { backgroundColor: bgColor || '#EEE' };
	// if (imgBg) {
	//     modalBodyStyle = {
	//         backgroundImage: `url('${imgBg}')`,
	//         backgroundPosition: "top left",
	//         backgroundSize: "contain",
	//         backgroundRepeat: "no-repeat"
	//     };
	// }
	return (
		<div
			className="modal fade"
			id={`${id}_modal`}
			tabIndex="-1"
			role="dialog"
			aria-labelledby={`${id}_modal_title`}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-lg" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title"
							id={`${id}_modal_title`}
							dangerouslySetInnerHTML={{ __html: title }}
						/>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body" style={modalBodyStyle}>
						<PrismaZoom
							className="App-zoom"
							minZoom={1}
							maxZoom={4}
							leftBoundary={0}
							topBoundary={0}
						>
							<img
								src={src}
								width="100%"
								height="auto"
								alt={id}
							/>
						</PrismaZoom>
					</div>
				</div>
			</div>
		</div>
	);
};

FileModal.propTypes = {
	id: PropTypes.string.isRequired,
	src: PropTypes.string
};

export default FileModal;
