import React from 'react';
import { findFromList } from '../../operations';
const QaData = (props) => {
  const { qaData, setqaData, user, vehData, view } = props;
  const role = user && user.role ? user.role : '';
  const username = user && user.username ? user.username : '';
  const messageData = qaData && qaData.messageData ? qaData.messageData : [];
  const inspectData = qaData && qaData.inspectData ? qaData.inspectData : [];
  const detected_damages =
    qaData && qaData.detected_damages ? qaData.detected_damages : [];
  let listAction = ['inspector', 'qa', 'support', 'review'];
  if (!['SUPER', 'TRAINER'].includes(role) && !view) {
    listAction = ['inspector', 'qa'];
  }
  const widthAction = 100 / listAction.length;
  const searchDataVehicle = (name, id) => {
    if (name === 'int_id') {
      const intColors = vehData.vehicle_int_colors || [];
      const intColor = findFromList(intColors, 'int_color', id ? id : false);
      return (
        <div style={{ display: 'inline-block' }}>
          <div
            className="name mr-2 text-head"
            style={{
              display: 'inline-block',
              textTransform: 'uppercase',
              padding: '3px 6px',
              background: '#321640',
              color: '#fff',
              borderRadius: '6px',
              fontSize: '12px',
            }}
          >
            {name ? name.replace('_', ' ') : ''}
          </div>
          <div className="data" style={{ display: 'inline-block' }}>
            {intColor.color_name ? intColor.color_name : ''}(
            {intColor.color_code ? intColor.color_code : ''})
          </div>
        </div>
      );
    }
    if (name === 'ext_id') {
      const extColors = vehData.vehicle_ext_colors || [];
      const extColor = findFromList(extColors, 'ext_color', id ? id : false);
      return (
        <div style={{ display: 'inline-block' }}>
          <div
            className="name text-head mr-2"
            style={{
              display: 'inline-block',
              textTransform: 'uppercase',
              padding: '3px 6px',
              background: '#321640',
              color: '#fff',
              borderRadius: '6px',
              fontWeight: 'bold',
              fontSize: '12px',
            }}
          >
            {name ? name.replace('_', ' ') : ''}
          </div>
          <div className="data mr-2" style={{ display: 'inline-block' }}>
            {extColor.color_name ? `(${extColor.color_name})` : ''}
            {extColor.generic_name ? extColor.generic_name : ''}(
            {extColor.color_code ? extColor.color_code : ''})
          </div>
          <div
            className="color"
            style={{
              width: '25px',
              height: '25px',
              border: '1px solid',
              background: `#${extColor.hex_value ? extColor.hex_value : ''}`,
              borderRadius: '50%',
              display: 'inline-block',
            }}
          ></div>
        </div>
      );
    }
    if (name === 'trim_id') {
      const vehicleTrimPack = vehData.vehicle_trims_pack || [];
      const checkSource =
        vehicleTrimPack &&
        vehicleTrimPack.length &&
        vehicleTrimPack.some((item) => item.trim && item.trim.source);
      const trims = checkSource ? vehicleTrimPack : vehData.vehicle_trims;
      const trim = findFromList(trims, 'trim', id ? id : false);
      return (
        <div style={{ display: 'inline-block' }}>
          <div
            className="name mr-2"
            style={{
              display: 'inline-block',
              textTransform: 'uppercase',
              padding: '3px 6px',
              background: '#321640',
              color: '#fff',
              borderRadius: '6px',
              fontWeight: 'bold',
              fontSize: '12px',
            }}
          >
            {name.replace('_', ' ')}
          </div>
          <div className="data mr-2" style={{ display: 'inline-block' }}>
            {trim
              ? `${trim.name ? trim.name : ''}
            ${trim.drivetrain && !checkSource ? trim.drivetrain : ''}
            ${trim.transmission ? trim.transmission : ''}
            ${
              trim.transmission_type_id
                ? trim.transmission_type_id && trim.transmission_type_id === 1
                  ? 'Auto'
                  : 'Manual'
                : ' '
            }
            ${trim.engine ? trim.engine : ''}
            ${trim.engine_type ? trim.engine_type : ''}
            ${trim.fuel_type && !checkSource ? trim.fuel_type : ''} 
            ${trim.mfr_model_code ? trim.mfr_model_code : ''}
            ${trim.brand && !checkSource ? trim.brand : ''}
            ${trim.model && !checkSource ? trim.model : ''}
            ${trim.year && !checkSource ? trim.year : ''}`
              : trim.trim_name
              ? trim.trim_name
              : id}
          </div>
        </div>
      );
    }
  };
  const updateDataQA = (action, id, type) => {
    const messageData = qaData && qaData.messageData ? qaData.messageData : [];
    const inspectData = qaData && qaData.inspectData ? qaData.inspectData : [];
    const detected_damages =
      qaData && qaData.detected_damages ? qaData.detected_damages : [];
    let trainerAction;
    if (['support', 'review'].includes(action.action)) {
      action = { ...action, critical: false };
    }
    if (['TRAINER', 'SUPER'].includes(role)) {
      trainerAction = { trainerAction: true, trainerUsername: username };
      if (['inspector', 'qa'].includes(action.action)) {
        action = { ...action, critical: true };
      }
    }
    switch (type) {
      case 'messageData':
        messageData[id] = { ...messageData[id], ...action, ...trainerAction };
        break;
      case 'inspectData':
        inspectData[id] = { ...inspectData[id], ...action, ...trainerAction };
        break;
      case 'detected_damages':
        detected_damages[id] = {
          ...detected_damages[id],
          ...action,
          ...trainerAction,
        };
        break;
      default:
        break;
    }

    const data = {
      messageData: messageData,
      inspectData: inspectData,
      detected_damages: detected_damages,
    };
    setqaData({ ...data });
  };
  return (
    <div className="qaData mb-2">
      {!view ? (
        <div
          className="title mb-2"
          style={{
            fontSize: '18px',
            background: '#321640',
            color: 'white',
            borderRadius: '6px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Qa
        </div>
      ) : null}

      {messageData.length > 0
        ? messageData.map((item, index) => {
            return (
              <div
                style={{ position: 'relative' }}
                id={`message_${index}`}
                key={`message_${index}`}
              >
                {item.done ? (
                  <div
                    className="done"
                    style={{
                      position: 'absolute',
                      right: '5px',
                      top: '0px',
                      color: '#28a745',
                      zIndex: '99',
                      fontSize: '22px',
                    }}
                  >
                    <i className="fas fa-check-circle"></i>
                  </div>
                ) : null}

                <li className="list-group-item">
                  <div style={{ display: 'inline-block' }}>
                    <div
                      style={{
                        color: '#fff',
                        background: '#321640',
                        display: 'inline-block',
                      }}
                      className="badge badge-primary text-head text-uppercase mr-2"
                    >
                      {item.name}
                    </div>
                    <div className="data" style={{ display: 'inline-block' }}>
                      {item.data}
                    </div>
                  </div>
                  <div className="mt-1" style={{ display: 'block' }}>
                    <div
                      style={{
                        opacity: item.critical ? '1' : '0.3',
                        fontSize: '14px',
                        zIndex: 99,
                        cursor: 'pointer',
                        pointerEvents:
                          !view &&
                          !item.done &&
                          ['TRAINER', 'SUPER'].includes(role)
                            ? ''
                            : 'none',
                      }}
                      className="badge badge-danger text-head"
                      onClick={() =>
                        updateDataQA(
                          { critical: !item.critical },
                          index,
                          'messageData',
                        )
                      }
                    >
                      critical
                    </div>
                  </div>
                </li>
                <div className="d-flex">
                  {listAction.map((itemAction, indexAction) => {
                    const action = item.action ? item.action : '';
                    const done = item.done ? item.done : false;
                    let backGround = '';
                    switch (itemAction) {
                      case 'inspector':
                        backGround = '#c0392b';
                        break;
                      case 'qa':
                        backGround = '#f39c12';
                        break;
                      case 'support':
                        backGround = '#34495e';
                        break;
                      case 'review':
                        backGround = '#1abc9c';
                        break;
                      default:
                        break;
                    }
                    return (
                      <div
                        style={{
                          width: `${widthAction}%`,
                          justifyContent: 'center',
                          borderRadius: '6px',
                          cursor: 'pointer',
                          margin: '3px',
                          pointerEvents: done || view ? 'none' : 'auto',
                          background: backGround,
                          opacity: action === itemAction ? '1' : `0.3`,
                        }}
                        key={`action_${indexAction}`}
                        className="action d-flex text-head text-white"
                        onClick={() =>
                          updateDataQA(
                            { action: itemAction },
                            index,
                            'messageData',
                          )
                        }
                      >
                        {itemAction}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        : null}
      {inspectData.length > 0
        ? inspectData.map((item, index) => {
            return (
              <div
                id={`inspectData_${index}`}
                className="mb-2"
                style={{
                  position: 'relative',
                }}
                key={`inspectData_${index}`}
              >
                {item.done ? (
                  <div
                    className="done"
                    style={{
                      position: 'absolute',
                      right: '5px',
                      top: '0px',
                      color: '#28a745',
                      zIndex: '99',
                      fontSize: '22px',
                    }}
                  >
                    <i className="fas fa-check-circle"></i>
                  </div>
                ) : null}
                <li
                  style={{ borderRadius: '6px' }}
                  className="list-group-item mb-1"
                >
                  {['int_id', 'trim_id', 'ext_id'].includes(item.name) ? (
                    searchDataVehicle(item.name, item.data)
                  ) : (
                    <div style={{ display: 'inline-block' }}>
                      <div
                        style={{
                          color: '#fff',
                          background: '#321640',
                          display: 'inline-block',
                        }}
                        className="badge badge-primary text-head text-uppercase mr-2"
                      >
                        {item.name}
                      </div>
                      <div className="data" style={{ display: 'inline-block' }}>
                        {item.data}
                      </div>
                    </div>
                  )}
                  <div className="mt-1" style={{ display: 'block' }}>
                    <div
                      style={{
                        opacity: item.critical ? '1' : '0.3',
                        fontSize: '14px',
                        cursor: 'pointer',
                        zIndex: 999,
                        pointerEvents:
                          !view &&
                          !item.done &&
                          ['TRAINER', 'SUPER'].includes(role)
                            ? ''
                            : 'none',
                      }}
                      className="badge badge-danger text-head"
                      onClick={() =>
                        updateDataQA(
                          { critical: !item.critical },
                          index,
                          'inspectData',
                        )
                      }
                    >
                      critical
                    </div>
                  </div>
                </li>
                <div className="d-flex">
                  {listAction.map((itemAction, indexAction) => {
                    const action = item.action ? item.action : '';
                    const done = item.done ? item.done : false;
                    let backGround = '';
                    switch (itemAction) {
                      case 'inspector':
                        backGround = '#c0392b';
                        break;
                      case 'qa':
                        backGround = '#f39c12';
                        break;
                      case 'support':
                        backGround = '#34495e';
                        break;
                      case 'review':
                        backGround = '#1abc9c';
                        break;
                      default:
                        break;
                    }
                    return (
                      <div
                        style={{
                          width: `${widthAction}%`,
                          justifyContent: 'center',
                          borderRadius: '6px',
                          cursor: 'pointer',
                          margin: '3px',
                          pointerEvents: done || view ? 'none' : 'auto',
                          background: backGround,
                          opacity: action === itemAction ? '1' : `0.3`,
                        }}
                        key={`action_${indexAction}`}
                        className="action d-flex text-head text-white"
                        onClick={() =>
                          updateDataQA(
                            { action: itemAction },
                            index,
                            'inspectData',
                          )
                        }
                      >
                        {itemAction}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        : null}
      {detected_damages.length > 0
        ? detected_damages.map((damage, index) => {
            let admin = '';
            switch (damage.admin_response) {
              case 'edit':
                admin = 'badge-primary';
                break;
              case 'reject':
                admin = 'badge-danger';
                break;
              case 'add':
                admin = 'badge-success';
                break;
              default:
                break;
            }
            if (view && !damage.admin_response) {
              return null;
            }
            return (
              <div
                className="mb-2"
                id={`damage_${index}`}
                key={`damage_${index}`}
                style={{ position: 'relative' }}
              >
                {damage.done ? (
                  <div
                    className="done"
                    style={{
                      position: 'absolute',
                      right: '5px',
                      top: '0px',
                      color: '#28a745',
                      zIndex: '99',
                      fontSize: '22px',
                    }}
                  >
                    <i className="fas fa-check-circle"></i>
                  </div>
                ) : null}
                <li
                  key={`DamageView_${index}`}
                  className="list-group-item mb-1"
                  style={{ borderRadius: '5px' }}
                >
                  {damage.admin_response && (
                    <div className="d-block mb-1">
                      <div className="d-flex">
                        <div
                          className={`badge ${admin} text-head text-white text-uppercase mr-1`}
                        >
                          Qa : {damage.admin_response}
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    id={`list-item-${index}`}
                    className="badge badge-danger text-head mr-1"
                  >
                    {index + 1}
                  </div>
                  {damage.material ? (
                    <div
                      style={{ color: '#fff' }}
                      className="badge badge-primary text-head text-uppercase mr-1"
                    >
                      {damage.material === 'Painted' ? (
                        <i className="fas fa-fill-drip"></i>
                      ) : damage.material === 'Textured Surface' ? (
                        <i className="fas fa-recycle"></i>
                      ) : damage.material === 'Chrome' ? (
                        <i className="far fa-gem"></i>
                      ) : damage.material === 'Alloy Wheel' ? (
                        <i className="fas fa-drum-steelpan"></i>
                      ) : damage.material === 'Plastic Cover' ? (
                        <i className="fas fa-record-vinyl"></i>
                      ) : damage.material === 'Steel Wheel' ? (
                        <i className="fas fa-dumbbell"></i>
                      ) : damage.material === 'Glass' ? (
                        <i className="fas fa-glass-whiskey"></i>
                      ) : damage.material === 'Steel' ? (
                        <i className="fas fa-dharmachakra"></i>
                      ) : null}
                    </div>
                  ) : null}
                  <div
                    style={{ color: '#fff' }}
                    className="badge badge-primary text-head text-uppercase mr-1"
                  >
                    {damage.unit_measure &&
                    (damage.unit_measure.includes('Component') ||
                      damage.unit_measure.includes('component'))
                      ? 'C'
                      : 'I'}
                  </div>
                  <div
                    id={`list-item-${index}`}
                    className="badge badge-secondary text-head mr-1"
                  >
                    {damage.component || 'Not yet defined'}
                  </div>
                  <div
                    style={{
                      color: damage.grade_score === 0 ? '#fff' : '#000',
                      background:
                        damage.grade_score === 0
                          ? 'grey'
                          : damage.grade_score === 1
                          ? 'yellow'
                          : damage.grade_score === 2
                          ? 'orange'
                          : damage.grade_score >= 3
                          ? 'red'
                          : '',
                    }}
                    className="badge badge-warning text-head mr-1 "
                  >
                    {damage.grade_score || 0}
                  </div>
                  {damage.damage_typeAgent ? (
                    <div
                      id={`damage-item-${index}`}
                      className="badge badge-warning text-head mr-1"
                    >
                      {damage.damage_typeAgent}[Agent]
                    </div>
                  ) : null}
                  <div
                    id={`damage-item-${index}`}
                    className="badge badge-warning text-head mr-1"
                    style={{
                      color: damage.grade_score === 0 ? '#fff' : '#000',
                      background:
                        damage.grade_score === 0
                          ? 'grey'
                          : damage.grade_score === 1
                          ? 'yellow'
                          : damage.grade_score === 2
                          ? 'orange'
                          : damage.grade_score >= 3
                          ? 'red'
                          : '',
                    }}
                  >
                    {damage.damage_name || 'Not yet defined'}
                  </div>
                  {damage.admin_response ? (
                    <div className="mt-1" style={{ display: 'block' }}>
                      <div
                        style={{
                          opacity: damage.critical ? '1' : '0.3',
                          fontSize: '14px',
                          cursor: 'pointer',
                          pointerEvents:
                            !view &&
                            !damage.done &&
                            ['TRAINER', 'SUPER'].includes(role)
                              ? ''
                              : 'none',
                        }}
                        className="badge badge-danger text-head"
                        onClick={() =>
                          updateDataQA(
                            { critical: !damage.critical },
                            index,
                            'detected_damages',
                          )
                        }
                      >
                        critical
                      </div>
                    </div>
                  ) : null}
                </li>

                <div className="d-flex">
                  {damage.admin_response &&
                    listAction.map((itemAction, indexAction) => {
                      const action = damage.action ? damage.action : '';
                      const done = damage.done ? damage.done : false;
                      let backGround = '';
                      switch (itemAction) {
                        case 'inspector':
                          backGround = '#c0392b';
                          break;
                        case 'qa':
                          backGround = '#f39c12';
                          break;
                        case 'support':
                          backGround = '#34495e';
                          break;
                        case 'review':
                          backGround = '#1abc9c';
                          break;
                        default:
                          break;
                      }
                      return (
                        <div
                          style={{
                            width: `${widthAction}%`,
                            justifyContent: 'center',
                            borderRadius: '6px',
                            cursor: 'pointer',
                            margin: '3px',
                            pointerEvents: done || view ? 'none' : 'auto',
                            background: backGround,
                            opacity: action === itemAction ? '1' : `0.3`,
                          }}
                          key={`action_${indexAction}`}
                          className="action d-flex text-head text-white"
                          onClick={() =>
                            updateDataQA(
                              { action: itemAction },
                              index,
                              'detected_damages',
                            )
                          }
                        >
                          {itemAction}
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default QaData;
