import React from 'react';

export default () => {
	return (
		<div id="Spinner_icon">
			<div>
				<i className="fas fa-car" />
				<i className="fas fa-user-secret" />
				<i className="fas fa-image" />
				<div />
			</div>
		</div>
	);
};
