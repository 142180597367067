import * as types from './types';
import { GET_ERRORS } from '../../actions/types';
const getAllCages = data => ({
	type: types.GET_CAGES,
	payload: {
		...data
	}
});

const getCage = data => ({
	type: types.GET_CAGE,
	payload: {
		...data
	}
});

const getCageDetail = data => ({
	type: types.GET_CAGE_DETAIL,
	payload: { ...data }
});

const postCageNote = data => ({
	type: types.POST_CAGE_NOTE,
	payload: { ...data }
});

const putCageDetail = data => ({
	type: types.PUT_CAGE_DETAIL,
	payload: {
		...data
	}
});

const putCageStatus = data => ({
	type: types.PUT_CAGE_STATUS,
	payload: {
		...data
	}
});

const putCageLabel = data => ({
	type: types.PUT_CAGE_LABEL,
	payload: {
		...data
	}
});

const uploadFile = data => ({
	type: types.FILE_UPLOAD,
	payload: {
		...data
	}
});

const hasError = err => ({
	type: GET_ERRORS,
	payload: err.response
});

const isLoading = () => ({
	type: types.IS_LOADING
});

export default {
	getAllCages,
	getCage,
	getCageDetail,
	postCageNote,
	putCageDetail,
	putCageStatus,
	putCageLabel,
	uploadFile,
	hasError,
	isLoading
};
