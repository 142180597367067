import React from 'react';
import { connect } from 'react-redux';
import Start from './Start';
import Process from './Process';

const AgentInspect = (props) => {
	let containers = {
		Start,
		Process,
	};
	let { status } = props.inspection;
	if (!Object.keys(containers).indexOf(status)) {
		status = 'Start';
	}
	const ContainerName = containers[status];
	const page = <ContainerName />;
	return <div className="InspectionBoard ">{page}</div>;
};

const mapStateToProps = (state) => ({
	inspection: state.inspection,
});

export default connect(mapStateToProps, null)(AgentInspect);
// export default connect(mapStateToProps, null)(AgentInspect);
