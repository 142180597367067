import React from 'react';

const ModalCheck = ({ clickOff, taskSlot, error, task }) => {
	if (
		taskSlot &&
		(taskSlot.inspects.name === 'vin' ||
			taskSlot.inspects.name === 'qc_plate') &&
		error === false &&
		task.message !== 'Success!'
	) {
		return (
			<div className={`modal check show-modal`} id="myModal">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">Inspection!</h4>
						</div>

						<div className="modal-body">
							<p>VinDecode Wrong Plz Input Again</p>
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className={`btn btn-primary `}
								onClick={clickOff}
							>
								Try Again
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default ModalCheck;
