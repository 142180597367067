import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import errorReducer from './reducers/errorReducer';
import taskReducer from './reducers/taskReducer';
import inspection from './app/inspections';
import cage from './app/cages';
import auth from './app/auth';
import ui from './app/ui';
import taskFailed from './app/taskFailed';
const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
};

const cagePersistConfig = {
  key: 'cage',
  storage: storage,
  whitelist: ['item'],
};

const rootReducer = combineReducers({
  auth,
  task: taskReducer,
  errors: errorReducer,
  inspection,
  cage: persistReducer(cagePersistConfig, cage),
  ui,
  taskFailed,
});

const pReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};

const middleware = [thunk];
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
  // other store enhancers if any
);

export const store = createStore(pReducer, initialState, enhancer);
export const persistor = persistStore(store);

// const store = createStore(rootReducer, initialState, enhancer);
// export default store;
