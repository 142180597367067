import { GET_ERRORS, CLEAR_ERRORS } from '../actions/types';

const initialState = {
	data: {},
	status: null,
	statusText: null
};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_ERRORS:
			let error = action.payload;
			if (error) {
				delete error.headers;
				delete error.config;
			}
			return error;
		case CLEAR_ERRORS:
			return initialState;
		default:
			return state;
	}
}
