import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getCage,
  postCageNote,
  putCageDetail,
  putCageStatus,
  uploadFile,
} from '../operations';
import {
  CageViewInfo,
  CageViewDetail,
  CageViewNotes,
  CageViewHistory,
} from './components';
import NoteModal from './widgets/NoteModal';
import jQuery from 'jquery';
window.jQuery = jQuery;
require('bootstrap');

class CageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: {
        type: 'INFORM',
        message: '',
      },
      upload: false,
      errors: {},
    };
    this.onCheck = this.onCheck.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onSubmitNote = this.onSubmitNote.bind(this);
    this.onChangeNote = this.onChangeNote.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.props.getCage(this.props.match.params.id);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.cage.uploaded) {
      this.props.getCage(this.props.match.params.id);
    }
  }

  onCheck(id, type, e) {
    if (e.target.checked) {
      this.props.putCageDetail(id, { type });
    }
  }

  onUpload(id, type, e) {
    const { item } = this.props.cage;
    const file = e.target.files[0];
    if (id && file) {
      this.setState({ upload: true });
      const formData = new FormData();
      formData.append('type', type);
      formData.append('file', file);
      this.props.uploadFile(id, formData, item._id);
    }
  }

  onChangeStatus(action = 'next', e) {
    e.preventDefault();
    this.props.putCageStatus(this.props.match.params.id, { action });
  }

  onSubmitNote(e) {
    e.preventDefault();
    const required = ['message'];
    const { note } = this.state;
    let errors = {};
    for (const index in required) {
      const key = required[index];
      if (!note[key]) {
        errors[key] = 'This is a required field';
      }
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
      return false;
    }
    this.props.postCageNote(this.props.match.params.id, note);
    jQuery('#Add_note').modal('hide');
    jQuery('.modal-backdrop').remove();
  }

  onChangeNote(e) {
    e.preventDefault();
    let noteData = this.state.note;
    noteData[[e.target.name]] = e.target.value;
    this.setState({ note: noteData });
  }

  render() {
    const { item, files, settings } = this.props.cage;
    const user = {
      ...this.props.auth.user,
      ...this.props.auth.account,
    };
    let heading;
    let content = [];
    if (item) {
      heading = `${item.year} ${item.make} ${item.model}`;

      content.push(
        <CageViewInfo
          key="Info"
          data={item}
          show={true}
          user={user}
          settings={settings}
          onChangeStatus={this.onChangeStatus}
          parent="#Cage_item_accordion"
        />,
      );

      content.push(
        <CageViewDetail
          key="Detail"
          data={item.cageDetails || []}
          files={files}
          cage={item}
          show={true}
          onCheck={this.onCheck}
          onUpload={this.onUpload}
          user={user}
          parent="#Cage_item_accordion"
        />,
      );

      content.push(
        <CageViewNotes
          key="Notes"
          data={item.cageNotes || []}
          settings={settings}
          parent="#Cage_item_accordion"
        />,
      );

      content.push(
        <CageViewHistory
          key="History"
          data={item.cageHistories || []}
          parent="#Cage_item_accordion"
        />,
      );
    }

    return (
      <div id="Cage_item">
        <div className="container-fluid">
          <h2 className="subtitle">
            <small>
              <Link to="/cages" className="text-logan">
                <i className="fa fa-arrow-left" />
                <span>Cage Manager</span>
              </Link>
            </small>
            <br />
            {heading}
          </h2>
          <div className="accordion" id="Cage_item_accordion">
            {content}
          </div>
        </div>
        <NoteModal
          cage={item}
          data={this.state.note}
          errors={this.state.errors}
          onSubmitNote={this.onSubmitNote}
          onChangeNote={this.onChangeNote}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cage: state.cage,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getCage,
  postCageNote,
  putCageDetail,
  putCageStatus,
  uploadFile,
})(CageView);
