import * as types from './types';
const initialState = {
  status: 'Start',
  listUrlAni: [
    { url: 'img/svg/ani1.svg', select: true },
    { url: 'img/svg/ani2.svg', select: false },
    { url: 'img/svg/ani3.svg', select: false },
  ],
  task: {},
  error: null,
  loading: false,
  timeCount: 60,
  timeOut: {},
  photoCode: 0,
  infoUser: {},
  sumaryTask: {},
  listDamages: {},
  doingTask: false,
  plusTime: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_MISSION:
      return {
        ...state,
        task: action.payload,
        error: null,
      };
    case types.PLUS_TIME:
      return {
        ...state,
        plusTime: true,
      };
    case types.GET_DAMAGES:
      return {
        ...state,
        listDamages: action.payload,
      };
    case types.CLEAR_LIST_DAMAGES:
      return {
        ...state,
        listDamages: {},
      };
    case types.SUMARY_TASK_USER:
      return {
        ...state,
        sumaryTask: action.payload,
        error: null,
      };
    case types.GET_TASKSLOT:
      return {
        ...state,
        task: action.payload,
        error: null,
      };
    case types.INFO_USER:
      return {
        ...state,
        infoUser: action.payload,
      };
    case types.TIME_OUT:
      return {
        ...state,
        timeOut: action.payload,
      };
    case types.SET_TIME:
      return {
        ...state,
        timeCount: action.payload,
      };
    case types.COUNT_TIME:
      return {
        ...state,
        timeCount: state.timeCount - 1,
      };
    case types.HAS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.STATUS_START:
      return {
        ...state,
        status: 'Start',
        task: {},
        error: null,
        loading: false,
        timeCount: 60,
        timeOut: {},
        listDamages: {},
        doingTask: false,
        plusTime: false,
      };

    case types.STATUS_PROCESS:
      return {
        ...state,
        status: 'Process',
      };
    case types.CHANGE_URL_ANIMATION:
      return {
        ...state,
        listUrlAni: action.payload,
      };
    case types.ACCEPT_IMAGE:
    case types.FINISH_IMAGE:
    case types.REJECT_IMAGE:
      const task = state.task || {};
      return {
        ...state,
        task: {
          ...task,
          ...action.payload,
        },
        listDamages: {},
      };
    case types.RESET_INSPECTION:
      return {
        ...state,
        status: 'Start',
        task: {},
        error: null,
        loading: false,
        timeCount: 60,
        timeOut: {},
        infoUser: {},
        sumaryTask: {},
        listDamages: {},
        doingTask: false,
        plusTime: false,
      };
    case types.CLEAR_MESSAGE:
      return {
        ...state,
        task: {
          ...state.task,
          message: '',
        },
      };
    case types.CLEAR_DATA_TASK:
      return {
        ...state,
        error: null,
        loading: false,
        timeCount: 60,
        timeOut: {},
        infoUser: {},
        sumaryTask: {},
        listDamages: {},
        task: {},
        doingTask: false,
        plusTime: false,
      };
    case types.DOING_TASK:
      return {
        ...state,
        doingTask: true,
      };
    case types.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
