import {
    GET_QUEUE_TASKS,
    GET_REJECT_TASKS,
    GET_QUEUE_TASK,
    GET_CURRENT_TASK,
    SET_CURRENT_TASK,
    CLEAR_CURRENT_TASK,
    IS_WAITING
} from "../actions/types";

const initialState = {
    cageProcess: null,
    cageQueue: [],
    cageRejects: [],
    waiting: true
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_QUEUE_TASKS:
            return {
                ...state,
                cageQueue: action.payload.data
            };
        case GET_REJECT_TASKS:
            return {
                ...state,
                cageRejects: action.payload.data
            };
        case GET_QUEUE_TASK:
        case SET_CURRENT_TASK:
        case GET_CURRENT_TASK:
            return {
                ...state,
                cageProcess: action.payload,
                waiting: false
            };
        case CLEAR_CURRENT_TASK:
            return {
                ...state,
                cageProcess: null
            };
        case IS_WAITING:
            return {
                ...state,
                waiting: true
            };
        default:
            return state;
    }
}
