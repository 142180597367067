import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CageListTableItem = ({ data }) => {
  return (
    <tr>
      <td>
        <div className="btn-group">
          <Link to={`/cageView/${data._id}`} className="btn btn-tiny btn-light">
            <i className="fas fa-pencil-alt"></i>
          </Link>
        </div>
      </td>
      <td>{data.group}</td>
      <td>{data.make}</td>
      <td>{data.model}</td>
      <td>{data.year}</td>
      <td>{data.bodyType}</td>
      <td>
        <small>{data.trim}</small>
      </td>
      <td>{data.code}</td>
      <td>{data.status}</td>
      <td className="text-center">
        <div className="badge badge-secondary">
          <i className="fas fa-file-alt"></i>
          <span>{data.cageNotes.length}</span>
        </div>
      </td>
    </tr>
  );
};

CageListTableItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CageListTableItem;
