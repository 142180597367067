import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AccordionItem, FileModal } from '../widgets';
import { defCageDetailOptions } from '../../settings';

const inputRef = {};
const CageViewDetail = ({
	data,
	files,
	show,
	onCheck,
	onUpload,
	cage,
	user,
	parent,
}) => {
	const bodyContent = (
		<table className="table table-sm table-bordered">
			<thead className="thead-dark">
				<tr>
					<th width="10%">Detail</th>
					<th width="11.25%">Photo</th>
					<th width="11.25%">Photo By</th>
					<th width="11.25%">Cage (SVG)</th>
					<th width="11.25%">Cage By</th>
					<th width="11.25%">Outline</th>
					<th width="11.25%">Outline By</th>
					<th width="11.25%">Label</th>
					<th width="11.25%">Label By</th>
				</tr>
			</thead>
			<tbody>
				{data.map((item) => {
					const file =
						item && files && files[item.code]
							? files[item.code]
							: false;
					let allowLabel = false;
					let modal = [];
					if (file) {
						for (const type in file) {
							const fileType = file[type] || {};
							const options = defCageDetailOptions[type] || {};
							modal[type] = [];
							if (fileType.url) {
								let buttonIcon = options.icon;
								if (fileType.fallback) {
									buttonIcon += ' text-logan';
								} else {
									buttonIcon += ' text-success';
									if (type === 'cage') {
										allowLabel = true;
									}
								}
								modal[type].push(
									<button
										key={`${type}_${item.code}_modal_button`}
										className="btn btn-tiny"
										data-toggle="modal"
										data-target={`#${type}_${item.code}_modal`}
									>
										<i className={buttonIcon} />
									</button>
								);
								modal[type].push(
									<FileModal
										key={`${type}_${item.code}_modal`}
										id={`${type}_${item.code}`}
										title={`${item.code}. ${item.name}: ${options.title} <span className="small text-secondary">(${fileType.folder})</span>`}
										src={fileType.url}
										bgColor={
											type === 'outline' ? '#333' : false
										}
									/>
								);
							} else {
								modal[type].push(
									<button
										key={`${type}_${item.code}_missing`}
										className="btn btn-tiny"
										disabled={true}
									>
										<i className="fa fa-unlink text-logan" />
									</button>
								);
							}
						}
					}
					let labelAction = [];
					if (allowLabel) {
						if (cage.status !== 'ACTIVE') {
							if (
								!item.labelBy ||
								item.labelBy._id === user._id
							) {
								labelAction.push(
									<Link
										key={`Label_btn_${item._id}`}
										to={`/cageLabel/${item._id}`}
										className={`btn btn-tiny mr-2 ${
											user.role !== 'AGENT'
												? 'btn-warning'
												: 'btn-light'
										}`}
									>
										<i className={`fa fa-fw fa-tags`} />
									</Link>
								);
							}
							if (item.labelBy && item.labelStatus !== 'DONE') {
								labelAction.push(
									<i
										key={`Label_processing_${item._id}`}
										className="fa fa-lg fa-cog fa-spin text-secondary"
									/>
								);
							}
						} else if (cage.status === 'ACTIVE') {
							labelAction.push(
								<Link
									key={`Label_btn_${item._id}`}
									to={`/cageLabel/${item._id}`}
									className={`btn btn-tiny mr-2 ${
										user.role !== 'AGENT'
											? 'btn-warning'
											: 'btn-light'
									}`}
								>
									<i className={`fa fa-fw fa-tags`} />
								</Link>
							);
						}
					}

					return (
						<tr key={item._id}>
							<td>
								<span className="badge badge-dark mr-2 text-head">
									{item.code}
								</span>{' '}
								{item.name}
							</td>
							<td>
								<div className="pull-left">
									<div className="custom-file">
										<input
											ref={(ref) =>
												(inputRef[
													'Photo_' + item.code
												] = ref)
											}
											type="file"
											accept="image/png, image/jpeg"
											onChange={onUpload.bind(
												this,
												item._id,
												'photoFile'
											)}
										/>
										{cage.status !== 'ACTIVE' ? (
											<button
												className="btn btn-tiny btn-light"
												onClick={() =>
													inputRef[
														'Photo_' + item.code
													].click()
												}
											>
												<i className="fa fa-fw fa-upload" />
											</button>
										) : null}
									</div>
								</div>
								<div className="pull-right">{modal.photo}</div>
							</td>
							<td>{item.photoBy ? item.photoBy.username : ''}</td>
							<td>
								<div className="pull-left">
									<div className="custom-file">
										<input
											ref={(ref) =>
												(inputRef['Cage_' + item.code] =
													ref)
											}
											type="file"
											accept="image/svg+xml"
											onChange={onUpload.bind(
												this,
												item._id,
												'cageFile'
											)}
										/>
										{cage.status !== 'ACTIVE' ? (
											<button
												className="btn btn-tiny btn-light"
												onClick={() =>
													inputRef[
														'Cage_' + item.code
													].click()
												}
											>
												<i className="fa fa-fw fa-upload" />
											</button>
										) : null}
									</div>
								</div>
								<div className="pull-right">{modal.cage}</div>
							</td>
							<td>{item.cageBy ? item.cageBy.username : ''}</td>
							<td>
								<div className="pull-left">
									<div className="custom-file">
										<input
											ref={(ref) =>
												(inputRef[
													'Outline_' + item.code
												] = ref)
											}
											type="file"
											accept="image/svg+xml"
											onChange={onUpload.bind(
												this,
												item._id,
												'outlineFile'
											)}
										/>
										{cage.status !== 'ACTIVE' ? (
											<button
												className="btn btn-tiny btn-light"
												onClick={() =>
													inputRef[
														'Outline_' + item.code
													].click()
												}
											>
												<i className="fa fa-fw fa-upload" />
											</button>
										) : null}
									</div>
								</div>
								<div className="pull-right">
									{modal.outline}
								</div>
							</td>
							<td>
								{item.outlineBy ? item.outlineBy.username : ''}
							</td>
							<td>
								<div className="pull-left">{labelAction}</div>
								<div className="pull-right">{modal.label}</div>
							</td>
							<td>{item.labelBy ? item.labelBy.username : ''}</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
	return (
		<AccordionItem
			id="detail"
			buttonLabel="Cage Detail"
			bodyContent={bodyContent}
			show={show}
			parent={parent}
		/>
	);
};

CageViewDetail.propTypes = {
	data: PropTypes.array.isRequired,
	cage: PropTypes.object.isRequired,
};

CageViewDetail.defaultProps = {
	show: false,
	data: [],
};

export default CageViewDetail;
