import { default as List } from './CageList';
import { default as View } from './CageView';
import { default as Label } from './CageLabel';
const Cage = {
	List,
	View,
	Label
};

export default Cage;
