import React from 'react';
import SpinnerIcon from '../../../../components/SpinnerIcon';
import Testing from '../../../../components/common/Testing';

const PhotoThumb = (props) => {
	let { checkTest } = props;
	return (
		<div className="img-thumb">
			{checkTest ? <Testing /> : null}
			<div className="group-thumb">
				<span>Pave Is Hard Working</span>
				<SpinnerIcon />
			</div>
		</div>
	);
};

export default PhotoThumb;
